import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ShoppingCartItemsContext } from './ShoppingCartStore';
import { MemberRoutes } from '..';
import styles from './ShoppingCartLink.module.scss';

export const ShoppingCartLink = React.memo(() => {
  const shoppingCart = useContext(ShoppingCartItemsContext);

  return (
    <Link to={MemberRoutes.Cart} className="d-flex align-items-center mr-3 my-sm-0 my-2">
      <span className={`${styles.counter} ${shoppingCart.length > 0 ? styles.nonZero : ''}`}>
        {shoppingCart.length}
      </span>
      <img src={require('../../assets/icons/shopping-cart.svg')} alt="Cart" />
    </Link>
  );
});
