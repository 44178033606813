import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';

import styles from './Calendar.module.scss';
import { CalendarBlockProps } from './index';
import { TimeSlot } from '../api/models';
import { TimeSlotIcon } from '../common';

interface CalendarProps<T, R> {
  data: T;
  dates: string[];
  timeSlots: TimeSlot[];
  loading?: boolean;
  CellComponent: React.FC<CalendarBlockProps<T, R>>;
  cellProps: R;
}

const CalendarNoMemo = <T, R>(props: CalendarProps<T, R>) => {
  return (
    <div className={styles.calendarWrapper}>
      {props.loading && <div className={styles.calendarLoader} />}
      <div className={styles.calendar}>
        <div className={styles.calendarRow}>
          <div className={styles.calendarHeaderCell} />
          {props.dates.map((date) => {
            const dateObj = parseISO(date);
            return (
              <div key={date} className={`${styles.calendarHeaderCell} text-center py-3`}>
                <div className={styles.headerDay}>{format(dateObj, 'dd')}</div>
                <div className={styles.headerDate}>{format(dateObj, 'eee MMM dd')}</div>
              </div>
            );
          })}
        </div>
        {props.timeSlots.map((timeSlot) => {
          return (
            <div key={timeSlot.name} className={styles.calendarRow}>
              <div className={styles.calendarBodyCell}>
                <TimeSlotLabel timeSlot={timeSlot} />
              </div>
              {props.dates.map((date) => {
                return (
                  <div key={date} className={styles.calendarBodyCell}>
                    <props.CellComponent
                      data={props.data}
                      date={date}
                      timeSlot={timeSlot}
                      cellProps={props.cellProps}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TimeSlotLabel = React.memo<{ timeSlot: TimeSlot }>(({ timeSlot }) => {
  const [startTime, endTime] = [timeSlot.startTime, timeSlot.endTime].map((x) =>
    format(parse(x, 'HH:mm:ss', new Date()), 'p'),
  );

  return (
    <div className="py-2 px-3">
      <TimeSlotIcon timeSlot={timeSlot} />
      <div className={styles.timeSlotLabel}>{timeSlot.name}</div>
      <div className={styles.timeSlotTimeSpan}>
        {startTime} &ndash; {endTime}
      </div>
    </div>
  );
});

const CalendarMobileNoMemo = <T, R>(props: CalendarProps<T, R>) => {
  return (
    <>
      {props.dates.map((date) => {
        const dateObj = parseISO(date);
        return (
          <div key={date} className="border-bottom">
            <div className="row align-items-center">
              <div className="col-auto">
                <div className={styles.headerDay}>{format(dateObj, 'd')}</div>
              </div>
              <div className="col-auto">
                <div className={styles.headerDate}>{format(dateObj, 'eee MMM d')}</div>
              </div>
            </div>
            {props.timeSlots.map((timeSlot) => {
              return (
                <div key={timeSlot.name}>
                  <div className="row align-items-center" style={{ minHeight: 50 }}>
                    <div className="col-auto">
                      <TimeSlotLabelMobile timeSlot={timeSlot} />
                    </div>
                    <div className="col">
                      <props.CellComponent
                        data={props.data}
                        date={date}
                        timeSlot={timeSlot}
                        cellProps={props.cellProps}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

const TimeSlotLabelMobile = React.memo<{ timeSlot: TimeSlot }>(({ timeSlot }) => {
  return (
    <div className="d-flex align-items-center" style={{ width: 165 }}>
      <div className="mr-3">
        <TimeSlotIcon timeSlot={timeSlot} />
      </div>
      <div>
        <div className={styles.timeSlotLabel}>{timeSlot.name}</div>
        <div className={styles.timeSlotTimeSpan}>
          {timeSlot.startTime} &ndash; {timeSlot.endTime}
        </div>
      </div>
    </div>
  );
});

export const Calendar = React.memo(CalendarNoMemo) as typeof CalendarNoMemo;
export const CalendarMobile = React.memo(CalendarMobileNoMemo) as typeof CalendarMobileNoMemo;
