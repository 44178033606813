import React, { Suspense, useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, RouteProps } from 'react-router-dom';

import { Api, retrieveJwt, setupAuthHeader } from './api';
import { UserStatus, UserRole } from './api/models';
import { AuthRoutePrefix, AuthRoutes } from './auth';
import { MemberRoutePrefix } from './member';
import { AdminRoutePrefix } from './admin';
import { Footer } from './layout/Footer';

const AuthRoutesContainer = React.lazy(() => import('./auth/Routes'));
const MemberRoutesContainer = React.lazy(() => import('./member/Routes'));
const AdminRoutesContainer = React.lazy(() => import('./admin/Routes'));

interface AuthContextValues {
  bootstrapped: boolean;
  authenticated: boolean;
  userId: number;
  memberStatus: UserStatus;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
}

interface AuthContextWithActions extends AuthContextValues {
  refresh: () => void;
}

const AUTH_CONTEXT_DEFAULTS: AuthContextValues = {
  bootstrapped: false,
  authenticated: false,
  userId: -1,
  memberStatus: 'active',
  firstName: '',
  lastName: '',
  email: '',
  role: 'member',
};

export interface CardContextValues {
  cardBrand: string | undefined;
  cardExpMonth: number | undefined;
  cardExpYear: number | undefined;
  cardLast4: number | undefined;
}

const CARD_CONTEXT_DEFAULTS = {
  cardBrand: undefined,
  cardExpMonth: undefined,
  cardExpYear: undefined,
  cardLast4: undefined,
};

export const AuthContext = React.createContext<AuthContextWithActions>({
  ...AUTH_CONTEXT_DEFAULTS,
  refresh: () => {},
});
export const CardContext = React.createContext<CardContextValues>(CARD_CONTEXT_DEFAULTS);

function App() {
  const [authContext, setAuthContext] = useState<AuthContextValues>(AUTH_CONTEXT_DEFAULTS);
  const [cardContext, setCardContext] = useState<CardContextValues>(CARD_CONTEXT_DEFAULTS);

  useEffect(() => {
    bootstrap();

    async function bootstrap() {
      const { authDetails, cardDetails } = await tryToRestoreSession();
      setCardContext(cardDetails);
      setAuthContext(authDetails);
    }
  }, []);

  const authContextWithActions = useMemo<AuthContextWithActions>(() => {
    return {
      ...authContext,
      refresh: async () => {
        const { authDetails, cardDetails } = await tryToRestoreSession();
        setAuthContext(authDetails);
        setCardContext(cardDetails);
      },
    };
  }, [authContext]);

  if (!authContext.bootstrapped) {
    return null;
  }

  return (
    <AuthContext.Provider value={authContextWithActions}>
      <Router>
        <div id="smd-content">
          <Switch>
            <Route exact path="/">
              <Redirect to={AuthRoutes.SignIn} />
            </Route>
            <Route path={AuthRoutePrefix}>
              <Suspense fallback={null}>
                <AuthRoutesContainer />
              </Suspense>
            </Route>
            <GuardedRoute
              path={MemberRoutePrefix}
              allow={authContext.authenticated && ['admin', 'member'].includes(authContext.role)}
            >
              <Suspense fallback={null}>
                <CardContext.Provider value={cardContext}>
                  <MemberRoutesContainer />
                </CardContext.Provider>
              </Suspense>
            </GuardedRoute>
            <GuardedRoute path={AdminRoutePrefix} allow={authContext.authenticated && authContext.role === 'admin'}>
              <Suspense fallback={null}>
                <AdminRoutesContainer />
              </Suspense>
            </GuardedRoute>
            <Route>
              <div className="container-fluid text-center">
                <h1 className="display-1 my-5">404</h1>
              </div>
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;

const GuardedRoute = React.memo((props: RouteProps & { allow: boolean }) => {
  const { allow, children, ...routeProps } = props;
  return <Route {...routeProps}>{allow ? children : <Redirect to={AuthRoutes.SignIn} />}</Route>;
});

interface SessionContextValues {
  authDetails: AuthContextValues;
  cardDetails: CardContextValues;
}

async function tryToRestoreSession(): Promise<SessionContextValues> {
  const storedAuth = retrieveJwt();
  setupAuthHeader(storedAuth);
  const expirationInMs = storedAuth.expiration * 1000;
  if (expirationInMs < new Date().getTime()) {
    return {
      authDetails: {
        ...AUTH_CONTEXT_DEFAULTS,
        bootstrapped: true,
        authenticated: false,
      },
      cardDetails: {
        ...CARD_CONTEXT_DEFAULTS,
      },
    };
  } else {
    try {
      const response = await Api.users.get(storedAuth.userId);
      return {
        authDetails: {
          bootstrapped: true,
          authenticated: true,
          userId: storedAuth.userId,
          role: response.role,
          memberStatus: response.status,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
        },
        cardDetails: {
          cardBrand: response.cardBrand,
          cardExpMonth: response.cardExpMonth,
          cardExpYear: response.cardExpYear,
          cardLast4: response.cardLast4,
        },
      };
    } catch (e) {
      console.error('Could not fetch user from referenced JWT');
      return {
        authDetails: {
          ...AUTH_CONTEXT_DEFAULTS,
          bootstrapped: true,
          authenticated: false,
        },
        cardDetails: {
          ...CARD_CONTEXT_DEFAULTS,
        },
      };
    }
  }
}
