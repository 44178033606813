import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

import { TimeSlot, LocationAvailabilityBlock, Location, LocationRoom } from '../../../api/models';
import { BookingModalRow } from './BookingModalRow';
import { DateTimeSlotLabel, TimeSlotIcon } from '../../../common';

export interface BookingModalProps {
  location?: Location;
  timeSlot?: TimeSlot;
  rooms: LocationRoom[];
  availability: LocationAvailabilityBlock[];
  date: string;
}

export const BookingModal = React.memo((props: ModalProps & BookingModalProps) => {
  const { date, timeSlot, location, rooms, availability, ...modalProps } = props;

  if (date.length === 0 || !timeSlot || !location) {
    return null;
  }

  return (
    <Modal {...modalProps} aria-labelledby="booking-modal" size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="booking-modal">
          <div className="d-flex align-items-center">
            <TimeSlotIcon timeSlot={timeSlot} size="sm" />
            <span className="ml-2">
              <DateTimeSlotLabel timeSlot={timeSlot} date={date} />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <div className="container-fluid">
          {rooms.map((room, index) => {
            return (
              <BookingModalRow
                key={room.id}
                index={index}
                date={date}
                location={location}
                room={room}
                timeSlot={timeSlot}
                availability={availability.find((x) => x.roomId === room.id)}
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={props.onHide}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
});
