import React from 'react';

import { LocationsList } from './LocationsList';
import { isLoading, isResolved, useFetchLocations } from '../../api/hooks';
import { Card, CardBody, CardTitle } from '../../layout';

export const LocationsContainer = React.memo(() => {
  const locationsResponse = useFetchLocations();

  let content = null;
  if (isLoading(locationsResponse)) {
    content = (
      <div className="text-center py-5">
        <em>Loading</em>
      </div>
    );
  } else if (isResolved(locationsResponse)) {
    content = <LocationsList locations={locationsResponse.data} />;
  }

  return (
    <Card>
      <CardBody borderBottom thin>
        <CardTitle>Select Location</CardTitle>
      </CardBody>
      <CardBody>{content}</CardBody>
    </Card>
  );
});
