import { useCallback, useEffect, useState } from 'react';

// TODO: Add single global window resize listener
// TODO: Add throttling
export const useMobileWidth = (widthUpTo: number = 768) => {
  const [mobile, setMobile] = useState(window.innerWidth < widthUpTo);
  const resizeCheck = useCallback(() => setMobile(window.innerWidth < widthUpTo), [widthUpTo, setMobile]); // Debounce

  useEffect(() => {
    window.addEventListener('resize', resizeCheck);
    return () => window.removeEventListener('resize', resizeCheck);
  }, [resizeCheck]);

  return mobile;
};
