import { useDebounce } from '@react-hook/debounce';
import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import startOfWeek from 'date-fns/startOfWeek';

import {
  Calendar,
  CalendarMobile,
  DatePaginator,
  useDatePagination,
  useDateRangeFromPagination,
  useMobileWidth,
} from '../../calendar';
import { Card, CardBody } from '../../layout';
import { BookedProgressBar } from '../common';
import { AdminRoutes } from '../Routes';
import { LocationSummaryBlock } from '../../common';
import {
  isLoading,
  useFetchLocation,
  useFetchLocationBookings,
  useFetchLocationBookingsParams,
  useTimeSlots,
} from '../../api/hooks';
import { CalendarBlock, CalendarBlockData, CustomCalendarBlockProps } from './CalendarBlock';

export const LocationContainer = React.memo(() => {
  const { locationId } = useParams();

  const startDate = useMemo(() => startOfWeek(new Date()), []);
  const datePagination = useDatePagination(startDate, 7);
  const dateRangeForCalendar = useDateRangeFromPagination(datePagination.dates);

  // Fetch time slots
  const timeSlots = useTimeSlots();

  // Fetch location
  const locationFetchParams = useMemo(() => ({ locationId }), [locationId]);
  const locationResponse = useFetchLocation(locationFetchParams);

  // Fetch location calendar bookings
  const [debouncedDates, setDebouncedDates] = useDebounce(datePagination.dates, 250);
  useEffect(() => setDebouncedDates(datePagination.dates), [datePagination.dates]);
  const bookingFetchParams = useFetchLocationBookingsParams(locationId, debouncedDates);
  const locationBookingsResponse = useFetchLocationBookings(bookingFetchParams);

  const calendarData: CalendarBlockData = useMemo(() => {
    const map: CalendarBlockData = {
      location: {
        rooms: locationResponse.data.rooms,
      },
      bookings: {},
    };

    locationBookingsResponse.data.forEach((x) => {
      const key = `${x.date}-${x.timeSlotId}`;
      if (!map.bookings[key]) {
        map.bookings[key] = {
          roomsBooked: 0,
          doctors: [],
        };
      }
      map.bookings[key].roomsBooked = map.bookings[key].roomsBooked + 1;
      if (!map.bookings[key].doctors.find((doctor) => doctor.id === x.userId)) {
        map.bookings[key].doctors.push(x.user);
      }
    });

    return map;
  }, [locationBookingsResponse.data, locationResponse.data]);

  const calendarBlockProps: CustomCalendarBlockProps = useMemo(() => {
    return {
      onSelectDoctor: (doctorId: number) => {
        alert(`Navigate to doctor ${doctorId}`);
      },
    };
  }, []);

  // Determine which calendar to render based on browser width
  const CalendarComponent = useMobileWidth() ? CalendarMobile : Calendar;

  const percentBooked =
    parseInt(locationResponse.data.percentageBooked ? locationResponse.data.percentageBooked : '0') / 100;

  return (
    <Card>
      <CardBody>
        <div className="mb-3">
          <Link className="btn btn-link text-primary p-0" to={AdminRoutes.Locations}>
            Back to Locations
          </Link>
        </div>
        <div className="row">
          <div className="col-12 col-md">
            <LocationSummaryBlock location={locationResponse.data} />
          </div>
          <div className="col-12 col-md-auto">
            <BookedProgressBar value={percentBooked} />
          </div>
        </div>
      </CardBody>
      <CardBody borderTop borderBottom thin>
        <DatePaginator {...datePagination} />
      </CardBody>
      <CardBody>
        <CalendarComponent<CalendarBlockData, CustomCalendarBlockProps>
          data={calendarData}
          dates={dateRangeForCalendar}
          timeSlots={timeSlots}
          loading={isLoading(locationBookingsResponse)}
          CellComponent={CalendarBlock}
          cellProps={calendarBlockProps}
        />
      </CardBody>
    </Card>
  );
});
