import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { useFetch } from './useFetch';
import { UpdatedUser } from '../models';

const NULL_RESPONSE = {};

export const useRequestUpdateUser = (params: UpdatedUser) => {
  const [request, setRequest] = useState<Promise<any>>();
  useEffect(() => {
    if (params.id === -1) {
      return;
    }
    setRequest(Api.users.updateUser(params));
  }, [params]);
  return useFetch<any>(request, NULL_RESPONSE);
};
