import React from 'react';

import { ProgressBar } from 'react-bootstrap';
import { BarChartDataSet, BarChartDataPoint } from './BarChartContainer';

export const BarChart = React.memo<{ dataSet: BarChartDataSet}>(({ dataSet }) => {
  return(
    <div className="col-12 pt-3">
      <b className="pl-3">{dataSet.label}</b>
      <ul className={'col-12 mb-0 pt-3 pb-3'}>
        {dataSet.data.map((dataPoint: BarChartDataPoint) => {
            const { label, value} = dataPoint;

            return(
              <li key={label} className="row align-items-center py-2 ml-1">
                <div className="col-2">
                  <span>{label}</span>
                </div>
                <div className="col-8">
                  <ProgressBar now={value}  />
                </div>
                <div className="col-2">
                  <span>{`${value}%`}</span>
                </div>
              </li>
            )
          }
        )
        }
      </ul>
    </div>
  );
});
