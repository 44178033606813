import { useDebounce } from '@react-hook/debounce';
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import startOfWeek from 'date-fns/startOfWeek';

import { isLoading, useFetchUserBookings, useFetchUserBookingsParams, useTimeSlots } from '../../api/hooks';
import { CalendarBlock, CalendarBlockData, CustomCalendarBlockProps } from './CalendarBlock';
import {
  Calendar,
  CalendarMobile,
  DatePaginator,
  useDatePagination,
  useDateRangeFromPagination,
  useMobileWidth,
} from '../../calendar';
import { CardBody } from '../../layout';
import { GenericId, TimeSlot } from '../../api/models';
import { CancelModal } from './CancelModal';
import { CancelModalProps } from './CancelModal/CancelModal';

const NULL_CANCEL_MODAL_DATA: CancelModalProps = {
  date: '',
  timeSlot: undefined,
  bookings: [],
};

export const MemberScheduleContainer = React.memo(({ userId }: { userId: GenericId }) => {
  const timeSlots = useTimeSlots();
  const startDate = useMemo(() => startOfWeek(new Date()), []);
  const datePagination = useDatePagination(startDate, 7);
  const dateRangeForCalendar = useDateRangeFromPagination(datePagination.dates);

  const mobile = useMobileWidth();
  const CalendarComponent = mobile ? CalendarMobile : Calendar;

  // Fetch location calendar availability
  const [debouncedDates, setDebouncedDates] = useDebounce(datePagination.dates, 250);
  useEffect(() => setDebouncedDates(datePagination.dates), [datePagination.dates]);
  const [bookingsFetchParams, refreshBookingFetchParams] = useFetchUserBookingsParams(userId, debouncedDates);
  const userBookingsResponse = useFetchUserBookings(bookingsFetchParams);

  const calendarData: CalendarBlockData = useMemo(() => {
    const map: CalendarBlockData = {};

    userBookingsResponse.data.forEach((booking) => {
      const key = `${booking.date}-${booking.timeSlotId}`;
      if (!map[key]) {
        map[key] = [];
        const locationExists = map[key].find((x) => x.location.id === booking.locationId);
        if (!locationExists) {
          map[key].push({
            location: booking.location,
            rooms: [],
          });
        }
      }

      const location = map[key].find((x) => x.location.id === booking.locationId);
      if (location) {
        location.rooms.push(booking.room);
      }
    });

    return map;
  }, [userBookingsResponse.data]);

  // Booking cancellation
  const [cancelModalData, setCancelModalData] = useState(NULL_CANCEL_MODAL_DATA);

  const readyToShowModal = useMemo(() => {
    return cancelModalData.timeSlot && cancelModalData.date.length > 0 && cancelModalData.bookings.length > 0;
  }, [cancelModalData]);

  const hideModal = useCallback(
    (shouldRefresh: boolean) => {
      if (shouldRefresh) {
        refreshBookingFetchParams();
      }
      setCancelModalData(NULL_CANCEL_MODAL_DATA);
    },
    [refreshBookingFetchParams],
  );

  const onCancel = useCallback(
    (date: string, timeSlot: TimeSlot) => {
      const bookingsToCancel = userBookingsResponse.data.filter((x) => x.date === date && x.timeSlotId === timeSlot.id);
      setCancelModalData({
        date,
        timeSlot,
        bookings: bookingsToCancel,
      });
    },
    [userBookingsResponse.data],
  );

  // Calendar cell props
  const cellProps = useMemo(() => ({ onCancel }), [onCancel]);

  return (
    <>
      <CardBody borderTop borderBottom thin>
        <DatePaginator {...datePagination} />
      </CardBody>
      <CardBody>
        <CalendarComponent<CalendarBlockData, CustomCalendarBlockProps>
          data={calendarData}
          dates={dateRangeForCalendar}
          timeSlots={timeSlots}
          loading={isLoading(userBookingsResponse)}
          CellComponent={CalendarBlock}
          cellProps={cellProps}
        />
      </CardBody>
      {readyToShowModal && <CancelModal show={readyToShowModal} onHide={hideModal} {...cancelModalData} />}
    </>
  );
});
