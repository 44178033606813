import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { GenericId, User } from '../models';
import { useFetch } from './useFetch';

const NULL_USER: User = {
  id: -1,
  subscriptionId: -1,
  stripeCustomerId: '',
  stripePreferredPaymentMethodId: '',
  role: 'member',
  status: 'active',
  firstName: '',
  lastName: '',
  email: '',
  lastLogin: '',
  tmpPasswordExpiration: null,
  createdAt: '',
  member: {
    phone: '',
    practiceName: '',
    npi: '',
    specialty: '',
    licenseNumber: '',
    licenseState: '',
    licenseExpiration: '',
    insuranceProvider: '',
    insurancePolicyNumber: '',
    insuranceExpiration: '',
  },
  subscription: {
    id: -1,
    name: '',
    amount: 0,
    deposit: 0,
    bookingsIncluded: 0,
    bookingAmount: 0,
  },
  location: null,
  room: null,
  media: [],
};

export const useFetchUser = (params: { userId: GenericId }) => {
  const [request, setRequest] = useState<Promise<User>>();
  useEffect(() => {
    if (!params.userId) {
      return;
    }
    setRequest(Api.users.get(params.userId));
  }, [params]);
  return useFetch<User>(request, NULL_USER);
};
