import React from 'react';

import { Purchase } from '../../api/models';
import { CurrencyFormat } from '../../common';
import { format, parseISO } from 'date-fns';

export const PurchasesTable = React.memo((props: { purchases: Purchase[] }) => {
  const { purchases } = props;

  return (
    <>
      <table className="table smd-table">
        <thead className="thead-light">
          <tr>
            <th>
              <div className="smd-column-header">
                <div>Date</div>
              </div>
            </th>
            <th>
              <div className="smd-column-header">
                <div>Details</div>
              </div>
            </th>
            <th className="fit">
              <div className="smd-column-header">
                <div>Purchase Amount</div>
              </div>
            </th>
            <th className="fit">
              <div className="smd-column-header">
                <div>Payment Method</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {purchases.map((purchase) => {
              const bookingNames = purchase.bookings.map((booking) => {
                return `${booking.location.name} ${booking.room.name}`
              }).join(', ');  
              return (
                <tr key={purchase.id}>
                  <td className="fit smd-featured">{format(parseISO(purchase.createdAt), 'MMMM d, y')}</td>
                  <td>{bookingNames}</td>
                  <td className="fit">
                    <CurrencyFormat amount={purchase.amount} currency={purchase.currency} />
                  </td>
                  <td className="fit text-capitalize">
                    {
                      purchase.cardBrand ?
                        `${purchase.cardBrand} **** ${purchase.cardLast4}`
                        :
                        'Monthly Subscription'
                    }
                  </td>
                </tr>
              );
          })}
        </tbody>
      </table>
      {purchases.length === 0 && (
        <div className="p-5 text-center">
          <strong className="text-muted">No purchases available</strong>
        </div>
      )}
    </>
  );
});
