import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { isLoading, isResolved, useRequestBookingCancel } from '../../../api/hooks';

import { TimeSlot, Booking, GenericId } from '../../../api/models';
import { DateTimeSlotLabel, TimeSlotIcon } from '../../../common';
import { CancelModalRow } from './CancelModalRow';

export interface CancelModalProps {
  date: string;
  timeSlot?: TimeSlot;
  bookings: Booking[];
}

export const CancelModal = React.memo((props: ModalProps & CancelModalProps) => {
  const { date, timeSlot, bookings, onHide, ...modalProps } = props;

  const [bookingIdToCancel, setBookingIdToCancel] = useState<GenericId>();
  const [bookingIdConfirmed, setBookingIdConfirmed] = useState<GenericId>();
  const [cancelledBookingIds, setCancelledBookingIds] = useState<{ [key: string]: boolean }>({});

  if (date.length === 0 || !timeSlot) {
    return null;
  }

  const bookingCancelParams = useMemo(() => {
    return {
      id: bookingIdConfirmed ? bookingIdConfirmed : 0,
    };
  }, [bookingIdConfirmed]);
  const bookingCancelResponse = useRequestBookingCancel(bookingCancelParams);

  useEffect(() => {
    if (bookingIdConfirmed && isResolved(bookingCancelResponse)) {
      setCancelledBookingIds({
        ...cancelledBookingIds,
        [bookingIdConfirmed]: true,
      });
    }
  }, [bookingCancelResponse, bookingIdConfirmed]);

  const loading = isLoading(bookingCancelResponse);

  const wrappedOnHide = useCallback(() => {
    if (loading) {
      return;
    }
    onHide(Object.keys(cancelledBookingIds).length > 0);
  }, [loading, cancelledBookingIds, onHide]);

  return (
    <Modal {...modalProps} onHide={wrappedOnHide} aria-labelledby="cancel-modal" size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="cancel-modal">
          <div className="d-flex align-items-center">
            <TimeSlotIcon timeSlot={timeSlot} size="sm" />
            <span className="ml-2">
              <DateTimeSlotLabel timeSlot={timeSlot} date={date} />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <div className="container-fluid">
          {bookings.map((booking, index) => {
            return (
              <CancelModalRow
                key={booking.id}
                index={index}
                room={booking.room}
                bookingId={booking.id}
                cancellable={booking.isCancellable}
                pendingCancellation={booking.id === bookingIdToCancel}
                recentlyCancelled={cancelledBookingIds[booking.id] === true}
                disabled={loading}
                onCancel={setBookingIdToCancel}
                onCancelConfirm={setBookingIdConfirmed}
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={wrappedOnHide} disabled={loading}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
});
