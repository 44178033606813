import React from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

import { TimeSlot } from '../api/models';

export const DateTimeSlotLabel = React.memo(({ timeSlot, date }: { timeSlot: TimeSlot; date: string }) => {
  const dateLabel = format(parseISO(date), 'iiii, MMMM d');
  const timeLabel = [timeSlot.startTime, timeSlot.endTime]
    .map((x) => format(parse(x, 'HH:mm:ss', new Date()), 'p'))
    .join('–');

  return (
    <>
      {dateLabel}, {timeLabel}
    </>
  );
});
