import React, { useMemo, useState, useCallback } from 'react';

import { User, UserStatus } from '../../api/models';
import { SimpleStatCard, BasicSelect } from '../../common';
import { useFetchUsers, useFetchKPI } from '../../api/hooks';
import { Card, CardBody, CardTitle } from '../../layout';
import { InviteModal } from './InviteModal';
import { UsersTable } from './UsersTable';
import styles from './UsersContainer.module.scss';

export const UsersContainer = React.memo(() => {
  const [refreshUsersTrigger, setRefreshUsersTrigger] = useState(new Date());
  const usersResponse = useFetchUsers(refreshUsersTrigger);
  const kpiResponse = useFetchKPI();

  const kpi = kpiResponse.data;

  const [statusFilter, setStatusFilter] = useState<UserStatus | undefined>(undefined);
  const tableUsers = useMemo<User[]>(() => {
    let users = usersResponse.data;
    if (statusFilter) {
      users = users.filter((x) => x.status === statusFilter);
    }
    return users;
  }, [usersResponse.data, statusFilter]);

  const [showInviteModal, setShowInviteModal] = useState(false);
  const onInviteModalShow = useCallback(() => setShowInviteModal(true), []);
  const onInviteModalHide = useCallback((user?: User) => {
    setShowInviteModal(false);
    if (user) {
      setRefreshUsersTrigger(new Date());
    }
  }, []);

  let activePercent = kpi.totalMembers ? Math.round((kpi.activeMembers / kpi.totalMembers) * 100) : 0;
  let pendingPercent = kpi.totalMembers ? Math.round((kpi.pendingMembers / kpi.totalMembers) * 100) : 0;
  let bookedPercent = kpi.totalMembers ? Math.round((kpi.membersCurrentlyBooked / kpi.totalMembers) * 100) : 0;
  let inactivePercent = kpi.totalMembers ? Math.round((kpi.inactiveMembers / kpi.totalMembers) * 100) : 0;

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-3 mb-4 d-flex flex-column">
          <SimpleStatCard
            label="Total Active Members"
            value={kpi.activeMembers || '-'}
            additional={`${activePercent}% of Total`}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-3 mb-4 d-flex flex-column">
          <SimpleStatCard
            label="Pending Members"
            value={kpi.pendingMembers || '-'}
            additional={`${pendingPercent}% of Total`}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-3 mb-4 d-flex flex-column">
          <SimpleStatCard
            label="Inactive Members"
            value={kpi.inactiveMembers || '-'}
            additional={`${inactivePercent}% of Total`}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-3 mb-4 d-flex flex-column">
          <SimpleStatCard
            label="Members Currently Booked"
            value={kpi.membersCurrentlyBooked || '-'}
            additional={`${bookedPercent}% of Total`}
          />
        </div>
      </div>

      <Card className={`${styles.userListCard}`}>
        <CardBody thin>
          <div className="row align-items-center">
            <div className="col-12 col-sm mb-2 mb-sm-0">
              <CardTitle>Doctor Member List</CardTitle>
            </div>

            <div className="col-12 col-sm-auto">
              <div className="d-sm-flex align-items-center">
                <div className="order-12">
                  <button type="button" className="btn btn-primary btn-block py-2" onClick={onInviteModalShow}>
                    Add New Member
                  </button>
                </div>

                <div className="order-0 mt-2 mt-sm-0 mr-sm-2">
                  <BasicSelect
                    noGroupMargin
                    label=""
                    value={statusFilter}
                    onChange={(evt) => setStatusFilter((evt.currentTarget.value as UserStatus) || undefined)}
                  >
                    <option value="">All Statuses</option>
                    <option value="pending">Pending</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </BasicSelect>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        <UsersTable users={tableUsers} />
      </Card>
      {showInviteModal && <InviteModal show={showInviteModal} onHide={onInviteModalHide} />}
    </>
  );
});
