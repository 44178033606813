import React from 'react';

import { OnboardingSteps } from './OnboardingContainer';

import styles from './ProgressSteps.module.scss';

interface ProgressStepsProps {
  currentStep: OnboardingSteps;
}

const steps: string[] = ['General Info', 'Documents', 'Payment', 'Summary'];

export const ProgressSteps = (props: ProgressStepsProps) => {
  const { currentStep } = props;

  return (
    <div className="col-12 col-lg-8">
      <div className="row justify-content-around">
        {steps.map((step, index) => {
          const active = currentStep >= index;
          return (
            <div key={step} className={`${styles.progressStep} ${active ? styles.active : ''}`}>
              <div className={styles.stepDot} />
              <p className={`${active ? styles.current : ''}`}>{step}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
