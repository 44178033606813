import React from 'react';

import { User } from '../../api/models';
import { GeneralInfoFormSchema } from './GeneralInfo';

interface MembershipAgreementProps {
  template: string;
  generalInfo: GeneralInfoFormSchema;
  user: User;
  signed: boolean;
}

export const MembershipAgreement = React.memo((props: MembershipAgreementProps) => {
  const html = buildMembershipAgreement(props.template, props.generalInfo, props.user, props.signed);
  return (
    <div
      className="p-3 border"
      style={{ maxHeight: 350, overflowY: 'auto', background: '#fafafa' }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
});

export function buildMembershipAgreement(
  template: string,
  generalInfo: GeneralInfoFormSchema,
  user: User,
  signed: boolean,
) {
  let html = template;

  const firstName = new Option(generalInfo.firstName).innerHTML;
  const lastName = new Option(generalInfo.lastName).innerHTML;

  html = html.replaceAll('{{firstName}}', firstName);
  html = html.replaceAll('{{lastName}}', lastName);
  html = html.replaceAll('{{email}}', new Option(generalInfo.email).innerHTML);
  html = html.replaceAll('{{phone}}', new Option(generalInfo.phoneNumber).innerHTML);
  html = html.replaceAll('{{practiceName}}', new Option(generalInfo.practiceName).innerHTML);
  html = html.replaceAll('{{specialty}}', new Option(generalInfo.specialty).innerHTML);
  html = html.replaceAll('{{today}}', new Date().toLocaleDateString());

  html = html.replaceAll('{{locationName}}', user.location ? user.location.name : 'N/A');
  html = html.replaceAll('{{roomName}}', user.room ? user.room.name : 'N/A');

  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  const subscriptionCost = formatter.format(parseFloat((user.subscription.amount / 100).toFixed(2)));
  const subscriptionDeposit = formatter.format(parseFloat((user.subscription.deposit / 100).toFixed(2)));
  html = html.replaceAll('{{subscriptionName}}', user.subscription.name);
  html = html.replaceAll('{{subscriptionAmount}}', subscriptionCost);
  html = html.replaceAll('{{deposit}}', subscriptionDeposit);

  html = html.replaceAll(
    '{{signature}}',
    signed ? `<span class="cursive-signature">${firstName} ${lastName}</span>` : '',
  );

  return html;
}
