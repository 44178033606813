import React from 'react';
import { Link } from 'react-router-dom';

import { Card, CardBody } from '../../layout';
import { MemberRoutes } from '../Routes';

export const ShoppingCartSuccess = React.memo(() => {
  return (
    <Card>
      <CardBody>
        <div className="row justify-content-center py-5">
          <div className="col-12 col-md-6 text-center">
            <div className="mb-4">
              <img src={require('../../assets/illustrations/buildings.svg')} alt="Building illustration" />
            </div>
            <h3>Thank you! Your booking has been placed!</h3>
            <p className="my-4">
              You will receive a confirmation email shortly. Please note all bookings must be canceled 48 hours in
              advance.
            </p>
            <Link className="btn btn-primary" to={MemberRoutes.Dashboard}>
              View Schedule
            </Link>
          </div>
        </div>
      </CardBody>
    </Card>
  );
});
