import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { MemberScheduleContainer } from '../../common';
import { isLoading, isResolved, useFetchUser, useRequestResendInvite, useRequestUserStatus } from '../../api/hooks';
import { Card, CardBody, CardBackButton } from '../../layout';
import { AdminRoutes } from '../index';
import { MemberSummaryBlock } from './MemberSummaryBlock';
import { MemberDocumentsBlock } from './MemberDocumentsBlock';
import { MemberStatusBlock } from './MemberStatusBlock';
import { GenericId, UserStatus } from '../../api/models';

import styles from './UserContainer.module.scss';

export const UserContainer = React.memo(() => {
  const { userId } = useParams();

  // Fetch user
  const [userRefreshToken, setUserRefreshToken] = useState(new Date());
  const userFetchParams = useMemo(() => ({ userId }), [userId, userRefreshToken]);
  const userResponse = useFetchUser(userFetchParams);

  // Keep the last successfully loaded user around;
  const [user, setUser] = useState(userResponse.data);
  useEffect(() => {
    if (isResolved(userResponse)) {
      setUser(userResponse.data);
    }
  }, [userResponse]);

  // Resend invite
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const resendInviteFetchParams = useMemo(() => ({ email: inviteEmail }), [inviteEmail]);
  const inviteResponse = useRequestResendInvite(resendInviteFetchParams);
  const onResendInvite = useCallback(() => {
    setInviteEmail(user.email);
  }, [user.email]);

  // Update user status
  const [newUserStatus, setNewUserStatus] = useState<UserStatus>();
  const userStatusRequestParams = useMemo<{ userId: GenericId; status?: UserStatus }>(() => {
    return {
      userId: user.id,
      status: newUserStatus,
    };
  }, [user.id, newUserStatus]);
  const statusResponse = useRequestUserStatus(userStatusRequestParams);
  const onChangeStatus = useCallback(setNewUserStatus, []);
  useEffect(() => {
    // Refresh user after the status call completes
    if (isResolved(statusResponse)) {
      setUserRefreshToken(new Date());
    }
  }, [statusResponse]);

  // General loading checks
  const loading = [userResponse, inviteResponse, statusResponse].some(isLoading);
  const pending = user.status === 'pending';

  return (
    <Card>
      <CardBody thin>
        <CardBackButton to={AdminRoutes.Users}>Back to Doctor Member List</CardBackButton>
      </CardBody>
      <CardBody>
        <div className="row">
          <div className="col">
            <MemberSummaryBlock user={user} />
          </div>

          <div className="col-12 col-md-auto d-flex justify-content-center">
            <MemberStatusBlock
              user={user}
              loading={loading}
              onChangeStatus={onChangeStatus}
              onResendInvite={onResendInvite}
            />
          </div>

          <div className="col-12 col-md-auto d-flex justify-content-center">
            <MemberDocumentsBlock user={user} />
          </div>
        </div>
      </CardBody>
      <div className="position-relative">
        <MemberScheduleContainer userId={userId} />
        {pending && <CalendarOverlay />}
      </div>
    </Card>
  );
});

export const CalendarOverlay = React.memo(() => {
  return (
    <div className={`${styles.calendarOverlayBackdrop} d-flex flex-column align-items-center`}>
      <Card className={`${styles.calendarOverlayModal} mx-3`}>
        <CardBody>
          <div className={`${styles.calendarOverlayMessaging} p-sm-4 m-0 text-center`}>
            <h4 className="mb-4">Calendar will be visible when account is activated.</h4>
            <p className="m-0">
              The user is currently pending. If all information has been received and verified correctly, you may change
              the status to approve. If any information is missing, notify the member of what is needed before we can
              move forward and complete the approval process.
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
});
