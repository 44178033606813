import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { useFetch } from './useFetch';

const NULL_RESPONSE = {};

export const useRequestForgotPassword = (params: { email: string }) => {
  const [request, setRequest] = useState<Promise<any>>();
  useEffect(() => {
    if (!params.email) {
      return;
    }
    setRequest(Api.auth.forgotPassword(params.email));
  }, [params]);
  return useFetch<any>(request, NULL_RESPONSE);
};
