import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { TimeSlot } from '../models';

let fetched = false;
let cachedTimeSlots: TimeSlot[] = [];

export function useTimeSlots() {
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>(cachedTimeSlots);

  useEffect(() => {
    if (fetched) {
      return;
    }
    fetch();
    async function fetch() {
      cachedTimeSlots = await Api.timeSlots.list();
      fetched = true;
      setTimeSlots(cachedTimeSlots);
    }
  }, []);

  return timeSlots;
}
