import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormOptions } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { Redirect } from 'react-router-dom';

import { BasicInput, Card, WelcomeSpash } from '../../common';
import { storeJwt } from '../../api';
import { isResolved, useRequestResetPassword, isError, isLoading } from '../../api/hooks';
import { MemberRoutes } from '../../member';
import { AdminRoutes } from '../../admin';
import { AuthContext } from '../../App';

import authStyles from '../Auth.module.scss';

interface FormSchema {
  email: string;
  tmpPassword: string;
  password: string;
  passwordConfirmation: string;
}

export const ResetPasswordContainer = React.memo(() => {
  const authContext = useContext(AuthContext);
  const formOptions = useMemo<UseFormOptions<FormSchema>>(() => {
    return {
      resolver: yupResolver(
        yup.object().shape({
          email: yup.string().email().required().label('Email'),
          tmpPassword: yup.string().required().label('Temporary Password'),
          password: yup.string().required().label('Password'),
          passwordConfirmation: yup
            .string()
            .oneOf([yup.ref('password')], 'Passwords do not match')
            .required()
            .label('Password Confirmation'),
        }),
        {
          abortEarly: false,
          recursive: true,
          strict: false,
        },
      ),
    };
  }, []);
  const { register, errors, handleSubmit } = useForm<FormSchema>(formOptions);

  const [resetPasswordSuccess, setRestPasswordSuccess] = useState(false);
  const [requestResetPasswordParams, setRequestResetPasswordParams] = useState({
    email: '',
    tmpPassword: '',
    password: '',
    passwordConfirmation: '',
  });
  const response = useRequestResetPassword(requestResetPasswordParams);
  const onResetPassword = useCallback((formValues: FormSchema) => {
    setRequestResetPasswordParams({ ...formValues });
  }, []);

  useEffect(() => {
    if (resetPasswordSuccess || !isResolved(response)) {
      return;
    }
    setRestPasswordSuccess(true);
    storeJwt({ ...response.data });
    authContext.refresh();
  }, [resetPasswordSuccess, authContext, response]);

  if (authContext.authenticated) {
    if (authContext.role === 'member') {
      return <Redirect to={MemberRoutes.Root} />;
    }

    if (authContext.role === 'admin') {
      return <Redirect to={AdminRoutes.Root} />;
    }
  }

  const error = isError(response);
  const loading = isLoading(response);

  return (
    <div className="row justify-content-center">
      <>
        <div className="col text-center">
          <WelcomeSpash />
        </div>
        <div className="col-12 col-md">
          <Card className="col" cardTitle="Reset your password">
            {error && (
              <div className={`col-12 ${authStyles.errorMessage}`}>
                Hmm… An error ocurred: {response.error?.message}
              </div>
            )}
            <form onSubmit={handleSubmit(onResetPassword)}>
              <BasicInput
                id="email"
                label="Email Address"
                errorMessage={errors.email?.message}
                name="email"
                disabled={loading}
                ref={register}
              />
              <BasicInput
                id="tmpPassword"
                label="Temporary Password"
                errorMessage={errors.tmpPassword?.message}
                name="tmpPassword"
                disabled={loading}
                ref={register}
              />
              <hr />
              <BasicInput
                id="password"
                type="password"
                label="New Password"
                errorMessage={errors.password?.message}
                name="password"
                disabled={loading}
                ref={register}
              />
              <BasicInput
                id="passwordConfirmation"
                type="password"
                label="Re-enter Password"
                errorMessage={errors.passwordConfirmation?.message}
                name="passwordConfirmation"
                disabled={loading}
                ref={register}
              />
              <div className="d-flex justify-content-center mt-5 mb-3">
                <button className="btn btn-secondary" type="submit" disabled={loading}>
                  Get Started
                </button>
              </div>
            </form>
          </Card>
        </div>
      </>
    </div>
  );
});
