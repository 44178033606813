import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { LocationSummaryBlock } from '../../common';

import styles from './LocationsRow.module.scss';
import { MemberRoutes } from '../Routes';
import { Location } from '../../api/models';
import { BookedProgressBar } from '../../admin/common';

export const LocationsRow = React.memo<{ location: Location }>(({ location }) => {
  const percentBooked = parseInt(location.percentageBooked ? location.percentageBooked : '0') / 100;

  return (
    <div className="row align-items-center">
      <div className="col-12 col-lg">
        <div className={styles.descriptionCol}>
          <LocationSummaryBlock location={location} />
        </div>
      </div>
      <div className="col-12 col-lg-auto text-center">
        <div className="mb-3">
          <BookedProgressBar value={percentBooked} />
        </div>
        <Link to={generatePath(MemberRoutes.Schedule, { locationId: location.id })} className="btn btn-primary">
          Check Availability
        </Link>
      </div>
    </div>
  );
});
