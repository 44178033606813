import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { GenericId } from '../models';
import { useFetch } from './useFetch';

const NULL_CANCEL = undefined;

interface RequestBookingCancel {
  id: GenericId;
}

export const useRequestBookingCancel = (params: RequestBookingCancel) => {
  const [request, setRequest] = useState<Promise<unknown>>();
  useEffect(() => {
    const { id } = params;
    if (!id) {
      return;
    }
    setRequest(Api.bookings.delete(params.id));
  }, [params]);
  return useFetch<unknown>(request, NULL_CANCEL);
};
