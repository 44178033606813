import React from 'react';

import { Card, CardBody, CardTitle } from '../../layout';
import { BarChart } from './BarChart'

interface BarChartData{
  topData: BarChartDataSet;
  bottomData: BarChartDataSet;
}

export interface BarChartDataSet{
  label: string;
  data: BarChartDataPoint[];
}

export interface BarChartDataPoint{
  label: string;
  value: number;
}

export const BarChartContainer = React.memo<{ data: BarChartData, title: string }>(({ data, title }) => {

  return(
    <Card>
      <CardBody thin>
        <div className="row justify-content-between">
          <div className="col-12 col-sm-8">
            <CardTitle><h2>{title}</h2></CardTitle>
          </div>
        </div>

        <div className="border border-light mb-2">
          <div className="row ">
            <BarChart dataSet={data.topData} />
          </div>

          <hr className="col-9"/>

          <div className="row">
            <BarChart dataSet={data.bottomData} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
});
