import * as yup from 'yup';

export const GeneralInfoSchema = yup.object().shape({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  practiceName: yup.string().required().label('Practice Name'),
  specialty: yup.string().label('Specialty'),
  email: yup.string().email().required().label('Email'),
  phoneNumber: yup.string().required().label('Phone Number'),
});

export const DocumentsSchema = yup.object().shape({
  idNumber: yup.string().required().label('Drivers License Number'),
  idStateIssued: yup.string().required().label('State Issued'),
  idExpirationDate: yup.string().required().label('Expiration Date'), // TODO: future date validation
  insuranceProvider: yup.string().required().label('Insurance Provider'),
  insurancePolicyNumber: yup.string().required().label('Policy Number'),
  insurancePolicyExpirationDate: yup.string().required().label('Expiration Date'), // TODO: future date validation
  npi: yup.string().required().label('NPI Number'),
  membershipAgreementAccepted: yup.boolean().oneOf([true], 'Membership agreement must be accepted'),
});

export const PaymentSchema = yup.object().shape({
  cardNumber: yup.string().required().label('Card Number'),
  expiration: yup.string().required().label('Expiration'),
  cvv: yup.string().required().label('CVV'),
});
