import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CircleChart } from './CircleChart';
import { BarChartContainer } from './BarChartContainer'
import { EventListContainer } from './EventListContainer';
import { KPI } from './KPI';
import { MemberList } from './MemberList';

export const DashboardContainer = React.memo(() => {
  const [ dashboardData, setDashboardData ] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get('https://virtserver.swaggerhub.com/TotallyNormalHumans/sharemd-suites/1.0.0/dashboard');
      setDashboardData(response.data);
    };

    getData();
  }, []);

  const renderDashboardComponent = (data: any) => {
    switch(data.type) {
      case 'kpi':
        return (
          <div className="row">
            {
              // simulating six data points by just rendering the one from test data six times
              (new Array(6)).fill(data).map((data, idx) => (
                <div key={idx} className="col-lg-2 col-md-3 col-4 d-flex justify-content-center px-0">
                  <KPI label={data.label} value={data.value} />
                </div>
              ))
            }
          </div>
        );
      case 'circle_chart':
        const circleChartData = data.data
          .slice()
          .sort((a: any, b: any) => b.value - a.value);

        return (
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4">
              <CircleChart title={data.title} data={circleChartData} />
            </div>
          </div>
        );
      case 'bar_chart':
        return(
          <div className="col-8">
            <BarChartContainer title={data.title} data={data} />
          </div>
        );
      case 'member_list':
        return (
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <MemberList title={data.title} members={data.data} />
            </div>
          </div>
        )
      case 'event_list':
        // assigning unique ids to each event as their original position in full events list data
        // this will be needed as key when mapping this array to list items in the EventList
        const events = data.data.map((event: any, idx: number) => ({ ...event, id: idx }))

        return (
          <EventListContainer title={data.title} events={events} />
        );
      default:
        return <div></div>
    }
  };

  return (
    <div>
      {
        dashboardData.map((data: any, index: number) =>
          <React.Fragment key={index}>
            {renderDashboardComponent(data)}
          </React.Fragment>
        )
      }
    </div>
  )
});
