import React from 'react';
import format from 'date-fns/format';
import isSameYear from 'date-fns/isSameYear';

import styles from './DatePaginator.module.scss';

interface DatePaginatorProps {
  dates: Date[];
  onPrev: () => void;
  onNext: () => void;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
}
export const DatePaginator = React.memo<DatePaginatorProps>((props) => {
  let dateRangeLabel = '';
  const firstDate = props.dates[0];
  const lastDate = props.dates[props.dates.length - 1];
  if (firstDate && lastDate) {
    const firstDateLabel = format(firstDate, isSameYear(firstDate, lastDate) ? 'MMMM d' : 'MMMM d, y');
    const lastDateLabel = format(lastDate, 'MMMM d, y');
    dateRangeLabel = `${firstDateLabel} – ${lastDateLabel}`;
  }
  return (
    <div className={styles.pagination}>
      <button
        className={`${styles.paginationButton} ${styles.prev}`}
        type="button"
        disabled={props.prevDisabled}
        onClick={props.onPrev}
      >
        <img src={require('../assets/interaction/caret-right.svg')} alt="Previous Week" />
      </button>
      <span className={styles.dateLabel}>{dateRangeLabel}</span>
      <button className={styles.paginationButton} type="button" disabled={props.nextDisabled} onClick={props.onNext}>
        <img src={require('../assets/interaction/caret-right.svg')} alt="Next Week" />
      </button>
    </div>
  );
});
