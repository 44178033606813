import React, { useEffect, useState } from 'react';

import { Card, StripeFormContainer } from '../../common';
import { OnboardingSteps } from './OnboardingContainer';
import { ProgressSteps } from './ProgressSteps';
import { PaymentInfo } from '../../common/PaymentInfo';

import cardIcon from '../../assets/icons/card-icon.svg';

export interface PaymentFormSchema {
  cardBrand: string | undefined;
  cardExpMonth: number | undefined;
  cardExpYear: number | undefined;
  cardLast4: number | undefined;
}
export const PAYMENT_DEFAULTS: PaymentFormSchema = {
  cardBrand: '',
  cardExpMonth: undefined,
  cardExpYear: undefined,
  cardLast4: undefined,
};

interface FormProps {
  disabled: boolean;
  defaultValues: PaymentFormSchema;
  onFinish: () => void;
  onUpdateCard: (paymentMethodId: string) => void;
  onBack: () => void;
}

export const Payment = React.memo((props: FormProps) => {
  const { onFinish, onBack, defaultValues, onUpdateCard, disabled } = props;
  const [cardDetails, setCardDetails] = useState<PaymentFormSchema>(defaultValues);

  useEffect(() => {
    setCardDetails(defaultValues);
  }, [defaultValues]);

  return (
    <div className="col-12 col-lg-8">
      <div className="d-flex flex-column align-items-center text-center">
        <h1>Let's set up your payment method.</h1>
        <p>You didn't think access to the best medical office space around was free, right?</p>
        <ProgressSteps currentStep={OnboardingSteps.PAYMENT} />
      </div>
      <Card>
        <div className="row">
          <div className="col-12 col-sm-4 d-flex flex-sm-column flex-row align-items-sm-start align-items-center mb-3">
            <div className="font-weight-bold">Credit/Debit Card</div>
            <img src={cardIcon} alt="Credit/Debit Card" className="mt-sm-3 ml-sm-0 ml-3" />
          </div>
          <div className="col mt-3 mt-md-0 mb-4">
            {cardDetails.cardBrand ? (
              <div className="row">
                <div className="col">
                  <PaymentInfo cardDetails={cardDetails} />
                </div>
                <div className="col-auto">
                  <button className="btn btn-link" onClick={() => setCardDetails(PAYMENT_DEFAULTS)}>
                    Update
                  </button>
                </div>
              </div>
            ) : (
              <>
                <StripeFormContainer onUpdateCard={onUpdateCard} loadingCard={disabled} displayInline={true} />
              </>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button className="btn btn-light" onClick={onBack}>
            Back
          </button>
          {!!cardDetails.cardBrand && (
            <button className="btn btn-secondary" onClick={onFinish} disabled={!cardDetails.cardBrand}>
              Next
            </button>
          )}
        </div>
      </Card>
    </div>
  );
});
