import React, { useContext, useMemo, useState, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import { format, subDays } from 'date-fns';

import styles from './PurchasesContainer.module.scss';
import { Card } from '../../layout';
import { useFetchUserPurchases, useFetchPurchasesDownload, isResolved, isLoading, isError } from '../../api/hooks';
import { AuthContext } from '../../App';
import { PurchasesTable } from './PurchasesTable';
import { FetchPurchasesDownloadParams } from '../../api/hooks/useFetchPurchasesDownload';
import authStyles from '../../auth/Auth.module.scss';

const DATE_FORMAT = 'yyyy-MM-dd';

export const PurchasesContainer = React.memo(() => {
  const { userId } = useContext(AuthContext);
  const fetchUserPurchasesParams = useMemo(() => ({ userId }), [userId]);
  const today = new Date();
  const ninetyDaysAgo = subDays(today, 90);
  const purchasesResponse = useFetchUserPurchases(fetchUserPurchasesParams);
  const [dateState, setDateState] = useState([ninetyDaysAgo, today]);
  const [purchasesDownloadParams, setPurchasesDownloadParams] = useState<FetchPurchasesDownloadParams>({
    userId,
    startDate: undefined,
    endDate: undefined,
  });
  const purchasesDownloadResponse = useFetchPurchasesDownload(purchasesDownloadParams);

  const onDownloadClicked = () => {
    const startDate = format(dateState[0], DATE_FORMAT);
    const endDate = dateState[1] ? format(dateState[1], DATE_FORMAT) : undefined;
    setPurchasesDownloadParams({ userId, startDate, endDate });
  };

  useEffect(() => {
    if (isResolved(purchasesDownloadResponse)) {
      const data = purchasesDownloadResponse.data;
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Purchases.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }
  }, [purchasesDownloadResponse]);

  const loading = isLoading(purchasesDownloadResponse);
  const error = isError(purchasesDownloadResponse);

  return (
    <>
      {error && (
        <div className={`col-12 mb-2 ${authStyles.errorMessage}`}>
          Hmm…There was an error: {purchasesDownloadResponse.error?.message}
        </div>
      )}
      <div className={styles.pickerContainer}>
        <span>
          Select Date Range:
          <Flatpickr
            name="purchasesDownloadDate"
            id="purchasesDownloadDate"
            value={dateState}
            options={{
              mode: 'range',
              dateFormat: 'm-d-Y',
              maxDate: today,
            }}
            className={`form-control ${styles.datePickerInput}`}
            onChange={(date) => {
              setDateState(date);
            }}
          />
          <button type="button" className="btn btn-light" disabled={loading} onClick={() => onDownloadClicked()}>
            Download CSV
          </button>
        </span>
      </div>
      <h3 className="mb-4">Your Purchase History</h3>
      <Card>
        <PurchasesTable purchases={purchasesResponse.data} />
      </Card>
    </>
  );
});
