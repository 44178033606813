import React from 'react';

import styles from './LocationSummaryBlock.module.scss';
import { Location } from '../api/models';
import { LocationAddressBlock } from './LocationAddressBlock';

export const LocationSummaryBlock = React.memo((props: { location: Location }) => {
  const { location } = props;

  return (
    <div className="row">
      <div className="col-12 col-lg-auto d-flex justify-content-center justify-content-lg-start">
        <div>
          <div
            className={styles.locationImage}
            style={{ backgroundImage: location.image ? `url(${location.image.src})` : undefined }}
          />
        </div>
      </div>
      <div className="col-12 col-lg my-3 my-lg-0">
        <LocationAddressBlock location={location} />
        <div className="mb-2">{location.details}</div>
        <div>
          {location.url && (
            <a href={location.url} target="_blank" rel="noopener noreferrer">
              View Property Details
            </a>
          )}
        </div>
      </div>
    </div>
  );
});
