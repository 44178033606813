import React, { useContext, useEffect, useMemo, useState } from 'react';

import { CurrencyFormat, StripeFormContainer } from '../../common';
import { Card, CardBody, CardTitle } from '../../layout';
import { BookingPreviewMonthlyUsage, BookingPreviewResponse } from '../../api/models';
import { ShoppingCartMonthlyUsage } from './ShoppingCartMonthlyUsage';
import { ShoppingCartRow } from './ShoppingCartRow';
import { ShoppingCartItem } from './ShoppingCartStore';

import styles from './ShoppingCart.module.scss';
import { PaymentInfo } from '../../common/PaymentInfo';
import { CardContext } from '../../App';

interface ShoppingCartProps {
  shoppingCart: ShoppingCartItem[];
  preview: BookingPreviewResponse[];
  monthlyUsage: BookingPreviewMonthlyUsage[];
  loading: boolean;
  conflicts: boolean;
  onSubmit: () => void;
  onUpdateCard: (paymentMethodId: string) => void;
  loadingCard: boolean;
}

export const ShoppingCart = React.memo((props: ShoppingCartProps) => {
  const { shoppingCart, preview, monthlyUsage, loading, conflicts, onSubmit, onUpdateCard, loadingCard } = props;

  const cardContext = useContext(CardContext);
  const [cardBrand, setCardBrand] = useState<string | undefined>(cardContext.cardBrand);

  useEffect(() => {
    setCardBrand(cardContext.cardBrand);
  }, [cardContext]);

  const totalCost = useMemo<number>(() => {
    return preview.reduce((acc, x) => {
      return acc + x.amount;
    }, 0);
  }, [preview]);

  const numberOfIncludedSlots = preview.filter((x) => x.status === 'included_in_subscription').length;

  const orderedShoppingCart = shoppingCart.sort((a, b) => {
    let comp = a.date.localeCompare(b.date);
    if (comp !== 0) {
      return comp;
    }

    comp = a.timeSlot.startTime.localeCompare(b.timeSlot.startTime);
    if (comp !== 0) {
      return comp;
    }

    comp = a.location.name.localeCompare(b.location.name);
    if (comp !== 0) {
      return comp;
    }

    return a.room.name.localeCompare(b.room.name);
  });

  return (
    <div className={styles.loadingWrapper}>
      {loading && (
        <div className={styles.loadingOverlay}>
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <Card>
        <div className="d-lg-flex">
          <div className="flex-fill">
            <CardBody borderBottom thin>
              <CardTitle>Order Summary</CardTitle>
            </CardBody>
            <CardBody>
              {orderedShoppingCart.map((cartItem, index) => {
                const key = `${cartItem.location.id}-${cartItem.room.id}-${cartItem.date}-${cartItem.timeSlot.id}`;
                const matchingPreview = preview.find((x) => {
                  return (
                    x.locationId === cartItem.location.id &&
                    x.roomId === cartItem.room.id &&
                    x.timeSlotId === cartItem.timeSlot.id &&
                    x.date === cartItem.date
                  );
                });
                return (
                  <ShoppingCartRow
                    key={key}
                    first={index === 0}
                    last={index === shoppingCart.length - 1}
                    cartItem={cartItem}
                    preview={matchingPreview}
                  />
                );
              })}
            </CardBody>
          </div>

          <div className={styles.rightColumn}>
            <CardBody borderBottom thin>
              <CardTitle>Payment</CardTitle>
            </CardBody>

            <CardBody borderBottom>
              {monthlyUsage.map((usage, index) => {
                return (
                  <div key={usage.month} className={index === 0 ? '' : 'mt-3'}>
                    <ShoppingCartMonthlyUsage usage={usage} />
                  </div>
                );
              })}
            </CardBody>

            <CardBody borderBottom>
              <div className="row mb-3">
                <div className="col">
                  <span className={styles.label}>Total</span>
                </div>
                <div className="col-auto text-right">
                  <strong>
                    <CurrencyFormat amount={totalCost} />
                  </strong>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <small>
                    Using {numberOfIncludedSlots} slot{numberOfIncludedSlots !== 1 ? 's' : ''} on monthly subscription
                  </small>
                </div>
                <div className="col-auto">
                  <strong>
                    {numberOfIncludedSlots} Slot{numberOfIncludedSlots !== 1 ? 's' : ''} Used
                  </strong>
                </div>
              </div>
            </CardBody>

            <CardBody borderBottom>
              <div className="row">
                <div className="col">
                  <span className={styles.label}>Payment Method</span>
                </div>
                {cardBrand ? (
                  <div className="col-auto">
                    <PaymentInfo cardDetails={cardContext} showLabel={false} textAlign="text-right" />
                    <button
                      className="btn btn-link px-0"
                      onClick={() => {
                        setCardBrand(undefined);
                      }}
                    >
                      Change Payment
                    </button>
                  </div>
                ) : (
                  <div className="col-12">
                    <StripeFormContainer onUpdateCard={onUpdateCard} loadingCard={loadingCard} displayInline={false} />
                  </div>
                )}
              </div>
            </CardBody>

            {conflicts && (
              <CardBody borderBottom className={styles.errorCardRow}>
                <strong className="d-block">
                  It looks like there were some issues with your cart. Remove any items that may have conflicts and try
                  again.
                </strong>
              </CardBody>
            )}

            <CardBody>
              <button type="button" className="btn btn-primary" onClick={onSubmit} disabled={loading || !cardBrand}>
                Place Order
              </button>
            </CardBody>
          </div>
        </div>
      </Card>
    </div>
  );
});
