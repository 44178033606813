import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';

import { logOut } from '../api/client';
import { AuthContext } from '../App';

import styles from './UserDropdown.module.scss';

export const UserDropdown = React.memo(() => {
  const authContext = useContext(AuthContext);

  const onLogOut = () => {
    logOut();
    authContext.refresh();
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>Welcome, {authContext.firstName}!</Dropdown.Toggle>

      <Dropdown.Menu>
        {/*// TODO: convert to a link to a new route that will mount, clear local storage and refresh Auth.*/}
        <Dropdown.Item onClick={() => onLogOut()}>Log Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
});

const CustomToggle = React.memo(
  React.forwardRef<HTMLButtonElement, React.HTMLProps<HTMLButtonElement>>((props, ref) => {
    const { children, onClick } = props;

    return (
      <button
        ref={ref}
        className={styles.userDropdownToggle}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
      >
        {children}
      </button>
    );
  }),
);
