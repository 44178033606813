import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { MemberScheduleContainer } from '../../common';
import { AuthContext } from '../../App';
import { Card, CardBody, CardTitle } from '../../layout';
import { MemberRoutes } from '../Routes';

export const DashboardContainer = React.memo(() => {
  const { userId } = useContext(AuthContext);
  return (
    <Card>
      <CardBody thin>
        <div className="row align-items-center">
          <div className="col">
            <CardTitle>My Schedule</CardTitle>
          </div>
          <div className="col-auto">
            <Link className="btn btn-primary" to={MemberRoutes.Locations}>
              Book Space
            </Link>
          </div>
        </div>
      </CardBody>
      <MemberScheduleContainer userId={userId} />
    </Card>
  );
});
