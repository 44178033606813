/**
 * Array defining color codes to use in charts.
 * Color values taken from ShareMD color palette.
 */

export const CHART_COLORS = [
  // PRIMARY COLORS
  '#223266',
  '#25A5DE',
  '#8D5679',

  // SECONDARY COLORS
  '#434343',
  '#3F9EB1',
  '#ED7D2E',
  '#E0302D',

  // TERTIARY COLORS
  '#F69F5C',
  '#E53D73',
  '#9C356D',
  '#523680',
  '#9373B3'
];