import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { ClientSecret, GenericId } from '../models';
import { useFetch } from './useFetch';

interface FetchClientSecretParams {
  userId: GenericId;
}

const NULL_CLIENT_SECRET: ClientSecret = {
  clientSecret: '',
};

export const useFetchClientSecret = (params: FetchClientSecretParams) => {
  const [request, setRequest] = useState<Promise<ClientSecret>>();
  useEffect(() => setRequest(Api.users.clientSecret(params.userId)), [params]);
  return useFetch<ClientSecret>(request, NULL_CLIENT_SECRET);
};
