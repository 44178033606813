const snakeToCamelMemo = new Map<string, string>();
const camelToSnakeMemo = new Map<string, string>();
const camelToHyphenMemo = new Map<string, string>();

// Can't use comma as your separator, used internally to separate tokens
function convertCamelToSeparator(separator: string, memo: Map<string, string>) {
  return function (key: string) {
    if (!memo.has(key)) {
      memo.set(
        key,
        key
          .replace(/[A-Z0-9]/g, ',$&')
          .replace(/(^,|,$)/, '')
          .split(',')
          .map((x) => x.toLowerCase())
          .join(separator),
      );
    }

    return memo.get(key) || key;
  };
}

export function convertSnakeToCamel(key: string) {
  if (key.indexOf('_') === -1) {
    return key;
  }

  if (!snakeToCamelMemo.has(key)) {
    snakeToCamelMemo.set(
      key,
      key
        .split('_')
        .map((x, index) => (index === 0 ? x : x.charAt(0).toUpperCase() + x.slice(1)))
        .join(''),
    );
  }

  return snakeToCamelMemo.get(key) || key;
}

export const convertCamelToSnake = convertCamelToSeparator('_', camelToSnakeMemo);
export const convertCamelToHyphen = convertCamelToSeparator('-', camelToHyphenMemo);

export function transformKeys(data: any, transformFunc: (key: string) => string) {
  let key;
  for (key in data) {
    if (data[key] instanceof Object) {
      const newKey = transformFunc(key);
      if (key !== newKey) {
        data[newKey] = data[key];
        delete data[key];
      }
      data[newKey] = transformKeys(data[newKey], transformFunc);
    } else {
      const newKey = transformFunc(key);
      if (key !== newKey) {
        data[newKey] = data[key];
        delete data[key];
      }
    }
  }
  return data;
}
