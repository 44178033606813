import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { JWT } from '../models';
import { useFetch } from './useFetch';

interface RequestConfirmInviteParams {
  email: string;
  invite: string;
  password: string;
  passwordConfirmation: string;
}

const NULL_JWT: JWT = {
  userId: -1,
  token: '',
  refreshToken: '',
  expiration: 0,
};

export const useRequestConfirmInvite = (params: RequestConfirmInviteParams) => {
  const [request, setRequest] = useState<Promise<JWT>>();
  useEffect(() => {
    const { email, invite, password, passwordConfirmation } = params;
    if (!email || !invite || !password || !passwordConfirmation) {
      return;
    }
    setRequest(Api.auth.confirmInvite(email, invite, password, passwordConfirmation));
  }, [params]);
  return useFetch<JWT>(request, NULL_JWT);
};
