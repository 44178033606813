import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { GenericId, Location } from '../models';
import { useFetch } from './useFetch';

interface FetchLocationParams {
  locationId: GenericId;
}

const NULL_LOCATION: Location = {
  name: '',
  address: '',
  city: '',
  id: 0,
  lat: 0,
  lng: 0,
  state: '',
  suite: '',
  timeZone: '',
  zip: '',
  url: '',
  rooms: [],
  image: null,
  details: '',
  percentageBooked: '',
};

export const useFetchLocation = (params: FetchLocationParams) => {
  const [request, setRequest] = useState<Promise<Location>>();
  useEffect(() => setRequest(Api.locations.get(params.locationId)), [params]);
  return useFetch<Location>(request, NULL_LOCATION);
};
