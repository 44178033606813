import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { JWT } from '../models';
import { useFetch } from './useFetch';

interface RequestSignInParams {
  email: string;
  password: string;
}

const NULL_JWT: JWT = {
  userId: -1,
  token: '',
  refreshToken: '',
  expiration: 0,
};

export const useRequestSignIn = (params: RequestSignInParams) => {
  const [request, setRequest] = useState<Promise<JWT>>();
  useEffect(() => {
    if (!params.email || !params.password) {
      return;
    }
    setRequest(Api.auth.logIn(params.email, params.password));
  }, [params]);
  return useFetch<JWT>(request, NULL_JWT);
};
