import React from 'react';
import { PaymentFormSchema } from '../member/onboarding/Payment';
import styles from '../member/shoppingCart/ShoppingCart.module.scss';

interface PaymentInfoProps {
  cardDetails: PaymentFormSchema;
  showLabel?: boolean;
  textAlign?: 'text-center' | 'text-left' | 'text-right';
}

export const PaymentInfo = React.memo(
  ({ cardDetails, showLabel = true, textAlign = 'text-left' }: PaymentInfoProps) => {
    const { cardBrand, cardLast4, cardExpMonth, cardExpYear } = cardDetails;

    return (
      <div className={textAlign}>
        {showLabel && <div className={styles.label}>Payment Method</div>}
        <span className="text-uppercase">{cardBrand}</span>
        {` **** ${cardLast4}`} <br />
        Exp {`${cardExpMonth}/${cardExpYear}`}
      </div>
    );
  },
);
