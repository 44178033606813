import React, { useState } from 'react';

import { BasicSelect } from '../../common';
import { Card, CardBody, CardTitle } from '../../layout';
import { EventList } from './EventList';

export interface Event {
  id: number;
  datetime: string;
  type: string;
  location: string;
  link: EventLink;
}

export interface EventLink {
  label: string;
  url: string;
}

export const EventListContainer = React.memo<{ events: Event[], title: string }>(({ title, events }) => {
  const [ selectedLocation, setSelectedLocation ] = useState('');

  const allLocations = events.map(event => event.location);
  const uniqueLocations = Array.from(new Set(allLocations));

  const getFilteredEvents = (): Event[] => {
    if(selectedLocation === '') {
      return events;
    }
    return events.filter(event => event.location === selectedLocation);
  };

  return (
    <Card>
      <CardBody thin>
        <div className="row justify-content-between">
          <div className="col-12 col-sm-8">
            <CardTitle><h2>{title}</h2></CardTitle>
          </div>
          <div className="col">
            <BasicSelect label=""
              value={selectedLocation}
              onChange={evt => setSelectedLocation(evt.currentTarget.value)}>
                <option value="">All Locations</option>
                { uniqueLocations.map(location => <option key={location} value={location}>{location}</option>) }
              </BasicSelect>
          </div>
        </div>
        <div className="row">
          <EventList className="col-12" events={getFilteredEvents()} />
        </div>
      </CardBody>
    </Card>
  )
});