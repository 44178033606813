import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { Purchase } from '../models';
import { useFetch } from './useFetch';

interface FetchUserPurchasesParams {
  userId: string | number;
}

const NULL_PURCHASES: Purchase[] = [];

export const useFetchUserPurchases = (params: FetchUserPurchasesParams) => {
  const [request, setRequest] = useState<Promise<Purchase[]>>();
  useEffect(() => {
    const { userId } = params;
    if (!userId) {
      setRequest(
        new Promise<Purchase[]>((resolve) => resolve(NULL_PURCHASES)),
      );
    }
    setRequest(Api.userPurchases.list(userId));
  }, [params]);
  return useFetch<Purchase[]>(request, NULL_PURCHASES);
};
