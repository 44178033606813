import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import inputStyles from '../BasicInput.module.scss';
import styles from './StripeForm.module.scss';

interface StripeFormProps {
  clientSecret: string;
  onSetPaymentMethod: (paymentMethodId: string) => void;
  onError: (message: string) => void;
  loading: boolean;
  displayInline: boolean;
}

export const StripeForm = React.memo(
  ({ clientSecret, onSetPaymentMethod, onError, loading, displayInline }: StripeFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [stripeLoading, setStripeLoading] = useState<boolean>(false);

    const showSpinner = loading || stripeLoading;

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      // Block native form submission.
      event.preventDefault();

      if (!stripe || !elements || !clientSecret) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        return;
      }

      // Use your card Element with other Stripe.js APIs
      setStripeLoading(true);
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        console.log('[error]', result.error);
        setStripeLoading(false);
        onError(result.error.message || '');
      } else {
        onSetPaymentMethod(result?.setupIntent?.payment_method || '');
        setStripeLoading(false);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        {displayInline && (
          <label htmlFor="cardElement" className={inputStyles.basicInputLabel}>
            Card Info
          </label>
        )}
        <div className={displayInline ? 'd-md-flex' : ''}>
          <div className="form-control flex-fill">
            <CardElement
              options={{
                style: {
                  base: {
                    color: '#424242',
                    fontFamily: '"Ubuntu", sans-serif',
                    fontSize: '14px',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#E02E2E',
                  },
                },
              }}
            />
          </div>
          <div className={`d-flex justify-content-end mt-2 ${displayInline ? 'mt-md-0' : 'mt-2'} position-relative`}>
            {showSpinner && (
              <div className={styles.spinnerWrapper}>
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Updating...</span>
                </div>
              </div>
            )}
            <div style={{ opacity: showSpinner ? 0 : 1 }}>
              <button className="btn btn-info" type="submit" disabled={!stripe || showSpinner}>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  },
);
