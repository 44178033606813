import React, { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { LocationRoom, User } from '../../api/models';
import { CalendarBlockProps } from '../../calendar';
import { AdminRoutes } from '../index';
import styles from './CalendarBlock.module.scss';

export interface CalendarBlockData {
  location: {
    rooms: LocationRoom[];
  };
  bookings: {
    [key: string]: {
      roomsBooked: number;
      doctors: User[];
    };
  };
}

export interface CustomCalendarBlockProps {
  onSelectDoctor: (doctorId: number) => void;
}

export const CalendarBlock = React.memo((props: CalendarBlockProps<CalendarBlockData, CustomCalendarBlockProps>) => {
  const key = useMemo(() => `${props.date}-${props.timeSlot.id}`, [props.date, props.timeSlot]);

  const rooms = props.data.location.rooms.length;

  const data = props.data.bookings[key];
  let roomsBooked = data ? data.roomsBooked : 0;
  let doctors: User[] = data ? data.doctors : [];

  return (
    <div className="py-2 px-3 d-flex flex-column flex-fill">
      <div className={`${styles.availableCount} mb-2`}>
        {roomsBooked} of {rooms} rooms booked
      </div>
      <div className={styles.avatars}>
        {doctors.map((user, index) => {
          return (
            <Link to={generatePath(AdminRoutes.User, { userId: user.id })} key={user.id} className={styles.avatar}>
              {user.firstName.charAt(0)}
              {user.lastName.charAt(0)}
            </Link>
          );
        })}
      </div>
    </div>
  );
});
