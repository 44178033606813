import React, { useContext } from 'react';

import { ShoppingCartActionsContext, ShoppingCartItem } from './ShoppingCartStore';
import { DateTimeSlotLabel, LocationAddressBlock, RoomSummaryBlock, TimeSlotIcon, CurrencyFormat } from '../../common';
import { BookingPreviewResponse } from '../../api/models';

import styles from './ShoppingCartRow.module.scss';

interface ShoppingCartRowProps {
  first: boolean;
  last: boolean;
  cartItem: ShoppingCartItem;
  preview?: BookingPreviewResponse;
}

export const ShoppingCartRow = React.memo((props: ShoppingCartRowProps) => {
  const { first, last, cartItem, preview } = props;
  const { location, date, timeSlot, room } = cartItem;

  const shoppingCartActions = useContext(ShoppingCartActionsContext);

  const inSubscription = preview?.status === 'included_in_subscription';
  const paymentRequired = preview?.status === 'additional_payment_required';
  const isBooked = preview?.status === 'booked';
  const isUnavailable = preview?.status === 'unavailable';

  return (
    <div className={`${!first ? 'pt-3' : ''} ${!last ? 'pb-3' : ''} ${!first ? 'border-top' : ''}`}>
      <div className="d-flex align-items-center mb-2">
        <TimeSlotIcon timeSlot={timeSlot} size="sm" />
        <span className="ml-2">
          <span className={styles.timeLabel}>
            <DateTimeSlotLabel timeSlot={timeSlot} date={date} />
          </span>
        </span>
      </div>
      <LocationAddressBlock location={location} />
      <div className="row align-items-end">
        <div className="col-12 col-lg">
          <RoomSummaryBlock room={room} />
        </div>
        <div className="col-12 col-lg-auto">
          <div className="mb-2">
            {inSubscription && '1 Slot'}
            {paymentRequired && <CurrencyFormat amount={(preview && preview.amount) || 0} />}
            {isBooked && <strong className="text-danger">Already Booked</strong>}
            {isUnavailable && <strong className="text-danger">Unavailable</strong>}
          </div>
          <button
            type="button"
            className={`btn ${isBooked || isUnavailable ? 'btn-danger' : 'btn-light'}`}
            onClick={() => shoppingCartActions.remove(cartItem)}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
});
