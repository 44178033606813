import React, { PropsWithoutRef, RefAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './Card.module.scss';

export const Card = React.memo((props: PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
  const { children, className, ...restProps } = props;

  return (
    <div className={`${styles.card} ${className || ''}`} {...restProps}>
      {children}
    </div>
  );
});

interface CardBodyProps {
  borderTop?: boolean;
  borderBottom?: boolean;
  thin?: boolean;
}

export const CardBody = React.memo((props: PropsWithoutRef<CardBodyProps & JSX.IntrinsicElements['div']>) => {
  const { children, className, borderTop, borderBottom, thin, ...restProps } = props;

  const classNames = `
    px-4 ${thin ? 'py-2' : 'py-4'}
    ${className || ''}
    ${borderTop ? 'border-top' : ''}
    ${borderBottom ? 'border-bottom' : ''}
  `;

  return (
    <div className={classNames} {...restProps}>
      {children}
    </div>
  );
});

export const CardTitle = React.memo((props: PropsWithoutRef<JSX.IntrinsicElements['div']>) => {
  const { children, className, ...restProps } = props;

  return (
    <div className={`${styles.cardHeader} ${className || ''}`} {...restProps}>
      {props.children}
    </div>
  );
});

export const CardBackButton = React.memo((props: PropsWithoutRef<LinkProps> & RefAttributes<HTMLAnchorElement>) => {
  const { className, children, ...rest } = props;
  return (
    <Link className={`btn btn-link text-primary p-0 d-flex align-items-center ${className ? className : ''}`} {...rest}>
      <img src={require('../assets/interaction/back-arrow.svg')} alt="Back" className="mr-2" />
      {children}
    </Link>
  );
});
