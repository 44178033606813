import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormOptions } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { Redirect } from 'react-router-dom';

import { BasicInput, Card, WelcomeSpash } from '../../common';
import { storeJwt } from '../../api';
import { isResolved, useRequestConfirmInvite, isError, isLoading } from '../../api/hooks';
import { MemberRoutes } from '../../member';
import { AdminRoutes } from '../../admin';
import { AuthContext } from '../../App';

import creditCard from '../../assets/icons/card-icon.svg';
import insurance from '../../assets/icons/insurance.svg';
import photoId from '../../assets/icons/photo-id.svg';
import npi from '../../assets/icons/npi-icon.svg';
import authStyles from '../Auth.module.scss';

interface FormSchema {
  email: string;
  invite: string;
  password: string;
  passwordConfirmation: string;
}

export const SignUpContainer = React.memo(() => {
  const authContext = useContext(AuthContext);
  const formOptions = useMemo<UseFormOptions<FormSchema>>(() => {
    return {
      resolver: yupResolver(
        yup.object().shape({
          email: yup.string().email().required().label('Email'),
          invite: yup.string().required().label('Temporary Password'),
          password: yup.string().required().label('Password'),
          passwordConfirmation: yup
            .string()
            .oneOf([yup.ref('password')], 'Passwords do not match')
            .required()
            .label('Password Confirmation'),
        }),
        {
          abortEarly: false,
          recursive: true,
          strict: false,
        },
      ),
    };
  }, []);
  const { register, errors, handleSubmit } = useForm<FormSchema>(formOptions);

  const [confirmInviteSuccess, setConfirmInviteSuccess] = useState(false);
  const [requestConfirmInviteParams, setRequestConfirmInviteParams] = useState({
    email: '',
    invite: '',
    password: '',
    passwordConfirmation: '',
  });
  const confirmInviteResponse = useRequestConfirmInvite(requestConfirmInviteParams);
  const onConfirmInvite = useCallback((formValues: FormSchema) => {
    setRequestConfirmInviteParams({ ...formValues });
  }, []);

  useEffect(() => {
    if (confirmInviteSuccess || !isResolved(confirmInviteResponse)) {
      return;
    }
    setConfirmInviteSuccess(true);
    storeJwt({ ...confirmInviteResponse.data });
    authContext.refresh();
  }, [confirmInviteSuccess, authContext, confirmInviteResponse]);

  if (authContext.authenticated) {
    if (authContext.role === 'member') {
      return <Redirect to={MemberRoutes.Root} />;
    }

    if (authContext.role === 'admin') {
      return <Redirect to={AdminRoutes.Root} />;
    }
  }

  const error = isError(confirmInviteResponse);
  const loading = isLoading(confirmInviteResponse);

  return (
    <div className="row justify-content-center">
      <>
        <div className="col text-center">
          <WelcomeSpash />
          <h6 className="font-weight-bold">Here's what you'll need to get started:</h6>
          <div className="d-flex align-items-end">
            <div className="flex-column px-3 align-items-end">
              <img src={photoId} alt="Photo ID" className="my-4" />
              <p>Photo ID</p>
            </div>
            <div className="flex-column px-3 align-items-end">
              <img src={insurance} alt="Insurance Information" className="my-4" />
              <p>Insurance Information</p>
            </div>
            <div className="flex-column px-3 align-items-end">
              <img src={npi} alt="National Provider Identifier" className="my-4" />
              <p>National Provider Identifier</p>
            </div>
            <div className="flex-column px-3 align-items-end">
              <img src={creditCard} alt="Payment Information" className="my-4" />
              <p>Payment Information</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md">
          <Card className="col" cardTitle="Login to your account">
            {error && (
              <div className={`col-12 ${authStyles.errorMessage}`}>
                Hmm…An error ocurred: {confirmInviteResponse.error?.message}
              </div>
            )}
            <form onSubmit={handleSubmit(onConfirmInvite)}>
              <BasicInput
                id="email"
                label="Email Address"
                errorMessage={errors.email?.message}
                name="email"
                disabled={loading}
                ref={register}
              />
              <BasicInput
                id="invite"
                label="Temporary Password"
                errorMessage={errors.invite?.message}
                name="invite"
                disabled={loading}
                ref={register}
              />
              <hr />
              <BasicInput
                id="password"
                type="password"
                label="New Password"
                errorMessage={errors.password?.message}
                name="password"
                disabled={loading}
                ref={register}
              />
              <BasicInput
                id="passwordConfirmation"
                type="password"
                label="Re-enter Password"
                errorMessage={errors.passwordConfirmation?.message}
                name="passwordConfirmation"
                disabled={loading}
                ref={register}
              />
              <div className="d-flex justify-content-center mt-5 mb-3">
                <button className="btn btn-secondary" type="submit" disabled={loading}>
                  Get Started
                </button>
              </div>
            </form>
          </Card>
        </div>
      </>
    </div>
  );
});
