import React, { useCallback, useState, useContext, useMemo, useEffect } from 'react';
import format from 'date-fns/format';

import { MediaResource, MediaCategory, UpdatedUser } from '../../api/models';
import { AuthContext } from '../../App';
import { GENERAL_INFO_DEFAULTS, GeneralInfo, GeneralInfoFormSchema } from './GeneralInfo';
import { Documents, DOCUMENTS_DEFAULTS, DocumentsFormSchema } from './Documents';
import { Payment, PAYMENT_DEFAULTS, PaymentFormSchema } from './Payment';
import { Summary } from './Summary';
import { HangTight } from './HangTight';
import {
  isError,
  isLoading,
  isResolved,
  useFetchUser,
  useRequestPreferredPaymentId,
  useRequestUpdateUser,
  useFetchMembershipAgreement,
} from '../../api/hooks';
import { Api } from '../../api';
import { buildMembershipAgreement } from './MembershipAgreement';

export enum OnboardingSteps {
  GENERAL_INFO,
  DOCUMENTS,
  PAYMENT,
  SUMMARY,
  HANG_TIGHT,
}

const NULL_UPDATE_USER: UpdatedUser = {
  id: -1,
  firstName: '',
  lastName: '',
  email: '',
  member: {
    phone: '',
    practiceName: '',
    npi: '',
    specialty: '',
    licenseNumber: '',
    licenseState: '',
    licenseExpiration: '',
    insuranceProvider: '',
    insurancePolicyNumber: '',
    insuranceExpiration: '',
  },
};

const DATE_FORMAT = 'yyyy-MM-dd';

export const OnboardingContainer = React.memo(() => {
  const [triggerPaymentUpdate, setTriggerPaymentUpdate] = useState<Date>();
  const [hasFetchedOnce, setHasFetchedOnce] = useState<boolean>(false);
  // Track current form step
  const [currentStep, setCurrentStep] = useState<OnboardingSteps>(OnboardingSteps.GENERAL_INFO);
  const onBack = useCallback(() => setCurrentStep(Math.max(0, currentStep - 1)), [currentStep]);

  // Fetch user
  const { userId } = useContext(AuthContext);
  const userFetchParams = useMemo(() => ({ userId }), [userId, triggerPaymentUpdate]);
  const userResponse = useFetchUser(userFetchParams);

  // Fetch membership agreement template
  const membershipAgreementParams = useMemo(() => ({}), []);
  const membershipAgreementResponse = useFetchMembershipAgreement(membershipAgreementParams);

  // Setup values to be passed to each screen's form
  const [generalInfo, setGeneralInfo] = useState<GeneralInfoFormSchema>(GENERAL_INFO_DEFAULTS);
  const [documents, setDocuments] = useState<DocumentsFormSchema>(DOCUMENTS_DEFAULTS);
  const [payment, setPayment] = useState<PaymentFormSchema>(PAYMENT_DEFAULTS);

  // Form media and uploading
  const [previouslyUploadedMedia, setPreviouslyUploadedMedia] = useState<MediaResource[]>([]);
  const [sessionUploadedMedia, setSessionUploadedMedia] = useState<MediaResource[]>([]);

  const onUploadMedia = useCallback(
    async (category: MediaCategory, file?: File | null) => {
      if (!file) {
        return;
      }
      try {
        const response = await Api.media.upload(userId, category, file);
        setSessionUploadedMedia([...sessionUploadedMedia, response]);
      } catch (e) {
        console.error('Error uploading file', e);
      }
    },
    [userId, sessionUploadedMedia],
  );

  const mostRecentIdentification = useMemo(() => {
    return [...previouslyUploadedMedia, ...sessionUploadedMedia]
      .filter((x) => x.category === 'identification')
      .sort((a, b) => b.id - a.id)[0];
  }, [previouslyUploadedMedia, sessionUploadedMedia]);

  const mostRecentInsurance = useMemo(() => {
    return [...previouslyUploadedMedia, ...sessionUploadedMedia]
      .filter((x) => x.category === 'insurance')
      .sort((a, b) => b.id - a.id)[0];
  }, [previouslyUploadedMedia, sessionUploadedMedia]);

  // Populate form values after receiving user object from API
  useEffect(() => {
    const setUserData = async () => {
      const { data } = userResponse;

      if (!isResolved(userResponse)) {
        return;
      }

      if (hasFetchedOnce) {
        setPayment({
          cardBrand: data.cardBrand,
          cardExpMonth: data.cardExpMonth,
          cardExpYear: data.cardExpYear,
          cardLast4: data.cardLast4,
        });
        return;
      }

      setGeneralInfo({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        specialty: data.member.specialty,
        phoneNumber: data.member.phone,
        practiceName: data.member.practiceName,
      });
      setDocuments({
        idNumber: data.member.licenseNumber,
        idStateIssued: data.member.licenseState,
        idExpirationDate: data.member.licenseExpiration,
        insuranceProvider: data.member.insuranceProvider,
        insurancePolicyNumber: data.member.insurancePolicyNumber,
        insurancePolicyExpirationDate: data.member.insuranceExpiration,
        npi: data.member.npi,
        membershipAgreementAccepted: false,
      });
      setPayment({
        cardBrand: data.cardBrand,
        cardExpMonth: data.cardExpMonth,
        cardExpYear: data.cardExpYear,
        cardLast4: data.cardLast4,
      });
      setPreviouslyUploadedMedia(userResponse.data.media);
      setHasFetchedOnce(true);
    };
    setUserData();
  }, [userResponse, hasFetchedOnce]);

  // Completion callbacks after each step
  const onFinishGeneralInfo = useCallback((formValues: GeneralInfoFormSchema) => {
    setGeneralInfo(formValues);
    setCurrentStep(OnboardingSteps.DOCUMENTS);
  }, []);

  const onFinishDocuments = useCallback((formValues: DocumentsFormSchema) => {
    const documentFormValues = {
      ...formValues,
      idExpirationDate: format(new Date(formValues.idExpirationDate), DATE_FORMAT),
      insurancePolicyExpirationDate: format(new Date(formValues.insurancePolicyExpirationDate), DATE_FORMAT),
    };
    setDocuments(documentFormValues);
    setCurrentStep(OnboardingSteps.PAYMENT);
  }, []);

  const [requestPaymentMethodId, setRequestPaymentMethodId] = useState<string>('');
  const preferredPaymentIdParams = useMemo(
    () => ({ stripePreferredPaymentMethodId: requestPaymentMethodId, userId: userId }),
    [userId, requestPaymentMethodId],
  );
  const responsePreferredPaymentId = useRequestPreferredPaymentId(preferredPaymentIdParams);
  const onUpdateCard = useCallback((paymentMethodId: string) => {
    setRequestPaymentMethodId(paymentMethodId);
  }, []);
  const loadingCard = isLoading(responsePreferredPaymentId);
  useEffect(() => {
    if (!isResolved(responsePreferredPaymentId)) {
      return;
    }
    setTriggerPaymentUpdate(new Date());
    setCurrentStep(OnboardingSteps.SUMMARY);
  }, [responsePreferredPaymentId]);

  const onFinishPayment = useCallback(() => {
    setCurrentStep(OnboardingSteps.SUMMARY);
  }, []);

  const [updateUserParams, setUpdateUserParams] = useState<UpdatedUser>(NULL_UPDATE_USER);
  const updateUserResponse = useRequestUpdateUser(updateUserParams);

  const onSubmitOnboarding = useCallback(() => {
    let membershipAgreement = '';
    if (documents.membershipAgreementAccepted) {
      const template = membershipAgreementResponse.data.html;
      membershipAgreement = buildMembershipAgreement(
        template,
        generalInfo,
        userResponse.data,
        documents.membershipAgreementAccepted,
      );
    }
    const updatedUser = {
      id: userResponse.data.id,
      email: generalInfo.email,
      firstName: generalInfo.firstName,
      lastName: generalInfo.lastName,
      membershipAgreement,
      member: {
        practiceName: generalInfo.practiceName,
        specialty: generalInfo.specialty,
        phone: generalInfo.phoneNumber,
        npi: documents.npi,
        licenseNumber: documents.idNumber,
        licenseState: documents.idStateIssued,
        licenseExpiration: documents.idExpirationDate,
        insurancePolicyNumber: documents.insurancePolicyNumber,
        insuranceProvider: documents.insuranceProvider,
        insuranceExpiration: documents.insurancePolicyExpirationDate,
      },
    };
    setUpdateUserParams(updatedUser);
  }, [generalInfo, documents, membershipAgreementResponse, userResponse]);

  const [summaryErrors, setSummaryErrors] = useState<string>('');
  useEffect(() => {
    if (!isResolved(updateUserResponse)) {
      return;
    }
    if (isError(updateUserResponse)) {
      setSummaryErrors(updateUserResponse.error?.message || '');
      return;
    }
    setCurrentStep(OnboardingSteps.HANG_TIGHT);
  }, [updateUserResponse]);

  // Render the current step
  let currentFormComponent;
  switch (currentStep) {
    case OnboardingSteps.GENERAL_INFO:
      currentFormComponent = (
        <GeneralInfo disabled={!isResolved(userResponse)} defaultValues={generalInfo} onFinish={onFinishGeneralInfo} />
      );
      break;
    case OnboardingSteps.DOCUMENTS:
      currentFormComponent = (
        <Documents
          disabled={!isResolved(membershipAgreementResponse)} // TODO: Need to disable during file uploads, show spinners etc
          defaultValues={documents}
          insuranceMedia={mostRecentInsurance}
          identificationMedia={mostRecentIdentification}
          membershipAgreementTemplate={membershipAgreementResponse.data.html}
          generalInfo={generalInfo}
          user={userResponse.data}
          onUploadMedia={onUploadMedia}
          onFinish={onFinishDocuments}
          onBack={onBack}
        />
      );
      break;
    case OnboardingSteps.PAYMENT:
      currentFormComponent = (
        <Payment
          disabled={loadingCard}
          defaultValues={payment}
          onUpdateCard={onUpdateCard}
          onFinish={onFinishPayment}
          onBack={onBack}
        />
      );
      break;
    case OnboardingSteps.SUMMARY:
      currentFormComponent = (
        <Summary
          defaultValues={{
            generalInfo,
            documents,
            payment,
          }}
          onSubmitOnboarding={onSubmitOnboarding}
          error={summaryErrors}
          onBack={onBack}
        />
      );
      break;
    case OnboardingSteps.HANG_TIGHT:
      currentFormComponent = <HangTight />;
      break;
    default:
      currentFormComponent = null;
  }

  return <div className="row justify-content-center">{currentFormComponent}</div>;
});
