import React, { PropsWithChildren } from 'react';

import { Card } from '../../common';
import { DocumentsFormSchema } from './Documents';
import { GeneralInfoFormSchema } from './GeneralInfo';
import { OnboardingSteps } from './OnboardingContainer';
import { PaymentFormSchema } from './Payment';
import { ProgressSteps } from './ProgressSteps';
import styles from './Summary.module.scss';
import { PaymentInfo } from '../../common/PaymentInfo';
import authStyles from '../../auth/Auth.module.scss';

interface OnboardingValues {
  generalInfo: GeneralInfoFormSchema;
  documents: DocumentsFormSchema;
  payment: PaymentFormSchema;
}

interface FormProps {
  onBack: () => void;
  defaultValues: OnboardingValues;
  onSubmitOnboarding: () => void;
  error: string;
}

interface SummaryItemProps {
  label: string;
}

export const Summary = React.memo((props: FormProps) => {
  const { onBack, defaultValues, onSubmitOnboarding, error } = props;
  const { generalInfo, documents, payment } = defaultValues;

  return (
    <div className="col-12 col-lg-8">
      <div className="d-flex flex-column align-items-center text-center">
        <h1>Woohoo!</h1>
        <p>Just double check your info, and we're good to go.</p>
        <ProgressSteps currentStep={OnboardingSteps.SUMMARY} />
      </div>
      <Card>
        <div className="row">
          {error && <div className={`col-12 ${authStyles.errorMessage}`}>{error}</div>}
          <div className="col-12 col-sm-3 font-weight-bold mb-3">General Info</div>
          <div className="col">
            <div className="row">
              <div className="col-6">
                <SummaryItem label="Name">{`${generalInfo.firstName} ${generalInfo.lastName}`}</SummaryItem>
              </div>
              <div className="col-6">
                <SummaryItem label="Email">{generalInfo.email}</SummaryItem>
              </div>
              <div className="col-6">
                <SummaryItem label="Practice">{generalInfo.practiceName}</SummaryItem>
              </div>
              <div className="col-6">
                <SummaryItem label="Specialty">{generalInfo.specialty}</SummaryItem>
              </div>
              <div className="col-6">
                <SummaryItem label="phone">{generalInfo.phoneNumber}</SummaryItem>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 col-sm-3 font-weight-bold mb-3">Medical License</div>
          <div className="col">
            <div className="row">
              <div className="col-12">
                <SummaryItem label="License Number">{documents.idNumber}</SummaryItem>
              </div>
              <div className="col-6">
                <SummaryItem label="State Issued">{documents.idStateIssued}</SummaryItem>
              </div>
              <div className="col-6">
                <SummaryItem label="Expiration Date">{documents.idExpirationDate}</SummaryItem>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 col-sm-3 font-weight-bold mb-3">Insurance</div>
          <div className="col">
            <div className="row">
              <div className="col-12">
                <SummaryItem label="Insurance Provider">{documents.insuranceProvider}</SummaryItem>
              </div>
              <div className="col-6">
                <SummaryItem label="Policy Number">{documents.insurancePolicyNumber}</SummaryItem>
              </div>
              <div className="col-6">
                <SummaryItem label="Expiration Date">{documents.insurancePolicyExpirationDate}</SummaryItem>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 col-sm-3 font-weight-bold mb-3">National Provider Identifier</div>
          <div className="col">
            <div className="row">
              <div className="col-12">
                <SummaryItem label="NPI Number">{documents.npi}</SummaryItem>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 col-sm-3 font-weight-bold mb-3">Payment Info</div>
          <div className="col">
            <div className="row ml-0">
              <PaymentInfo cardDetails={payment} />
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <button className="btn btn-light" onClick={onBack}>
            Back
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              onSubmitOnboarding();
            }}
          >
            Submit
          </button>
        </div>
      </Card>
    </div>
  );
});

const SummaryItem = React.memo((props: PropsWithChildren<SummaryItemProps>) => {
  return (
    <div className="mb-3">
      <div className={styles.summaryItemLabel}>{props.label}</div>
      <div className="text-break">{props.children}</div>
    </div>
  );
});
