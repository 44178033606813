import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { Subscription } from '../models';
import { useFetch } from './useFetch';

const NULL_SUBSCRIPTIONS: Subscription[] = [];

export const useFetchSubscriptions = () => {
  const [request, setRequest] = useState<Promise<Subscription[]>>();
  useEffect(() => setRequest(Api.subscriptions.list()), []);
  return useFetch<Subscription[]>(request, NULL_SUBSCRIPTIONS);
};
