import React, { PropsWithoutRef } from 'react';

import styles from './Card.module.scss';

interface CardProps extends PropsWithoutRef<JSX.IntrinsicElements['div']> {
  cardTitle?: string;
}

export const Card = (props: CardProps) => {
  const { cardTitle, children, className, ...restProps } = props;

  return (
    <div className={`${styles.smdCard} ${className ? className : ''}`} {...restProps}>
      {cardTitle ? <div className={styles.smdCardTitle}>{cardTitle}</div> : null}
      <div className={styles.smdCardBody}>{children}</div>
    </div>
  );
};
