import React, { useMemo, ReactNode } from 'react';

import { User, MediaCategory, MediaResource } from '../../api/models';

interface DocType {
  category: MediaCategory;
  link: ReactNode;
  linkText: ReactNode;
  expiration: string;
  image: ReactNode;
  latestUpload?: MediaResource;
}

export const MemberDocumentsBlock = React.memo(({ user }: { user: User }) => {
  const documents = useMemo<DocType[]>(() => {
    const unavailableMessage = (
      <span className="text-muted">
        Document <br /> Unavailable
      </span>
    );
    const docTypes: DocType[] = [
      {
        category: 'identification',
        expiration: user.member.licenseExpiration,
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        image: <img src={require('../../assets/icons/photo-id.svg')} alt="Photo ID" />,
        link: unavailableMessage,
        linkText: 'Photo ID',
        latestUpload: undefined,
      },
      {
        category: 'insurance',
        expiration: user.member.insuranceExpiration,
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        image: <img src={require('../../assets/icons/insurance.svg')} alt="Insurance" />,
        link: unavailableMessage,
        linkText: 'Insurance',
        latestUpload: undefined,
      },
    ];
    docTypes.forEach((docType) => {
      if (!docType.expiration) {
        docType.expiration = 'Unknown';
      }
      docType.latestUpload = user.media.filter((x) => x.category === docType.category).sort((a, b) => b.id - a.id)[0];
      if (!docType.latestUpload) {
        return;
      }
      docType.link = (
        <a href={docType.latestUpload.src} target="_blank" rel="noopener noreferrer">
          View <br /> {docType.linkText}
        </a>
      );
    });

    return docTypes;
  }, [user]);

  return (
    <div className="row">
      {documents.map((docType) => {
        return (
          <div key={docType.category} className="d-flex flex-column px-3">
            <div className="mb-3 flex-grow-1 align-items-center justify-content-center d-flex">{docType.image}</div>
            <div className="text-center">
              <div className="text-muted">
                <small>Exp. {docType.expiration}</small>
              </div>
              {docType.link}
            </div>
          </div>
        );
      })}
    </div>
  );
});
