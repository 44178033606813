import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { SignInContainer } from './SignIn';
import { ForgotPasswordContainer } from './ForgotPassword';
import { ResetPasswordContainer } from './ResetPassword';
import { SignUpContainer } from './SignUp';

export const AuthRoutePrefix = '/auth';
export enum AuthRoutes {
  SignIn = '/auth/sign-in',
  SignUp = '/auth/sign-up',
  ForgotPassword = '/auth/forgot-password',
  ResetPassword = '/auth/reset-password',
}

export default React.memo(() => {
  return (
    <div className="container-fluid py-5 flex-fill d-flex flex-column justify-content-center">
      <Switch>
        <Route exact path={AuthRoutes.SignIn}>
          <SignInContainer />
        </Route>
        <Route exact path={AuthRoutes.SignUp}>
          <SignUpContainer />
        </Route>
        <Route exact path={AuthRoutes.ForgotPassword}>
          <ForgotPasswordContainer />
        </Route>
        <Route exact path={AuthRoutes.ResetPassword}>
          <ResetPasswordContainer />
        </Route>
        <Route>
          <Redirect to={AuthRoutes.SignIn} />
        </Route>
      </Switch>
    </div>
  );
});
