import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Card, CardTitle, CardBody } from '../../layout';
import { CHART_COLORS } from '../../utils/chartColors';
import styles from './CircleChart.module.scss';

interface CircleChartDataPoint {
  label: string;
  value: number;
}

export const CircleChart = React.memo<{ data: CircleChartDataPoint[], title: string }>(({ title, data }) => {
  const doughnutData = {
    labels: data.map((data: CircleChartDataPoint) => data.label),
    datasets: [
      {
        data: data.map((data: CircleChartDataPoint) => data.value),
        backgroundColor: CHART_COLORS,
        borderColor: CHART_COLORS,
        borderWidth: 1
      }
    ]
  };

  return (
    <Card>
      <CardBody thin>
        <CardTitle><h2>{title}</h2></CardTitle>
        <div className="py-2">
          <Doughnut data={doughnutData} 
            legend={{
              display: false
            }}
          />

          <ul className={`${styles.legend} list-unstyled my-0 mx-auto p-0`}>
            {
              data.map((dataPoint: CircleChartDataPoint, index: number) => {
                const { label, value } = dataPoint;
                const color = CHART_COLORS[index];
                return (
                  <li key={label} className="d-flex my-1 align-items-center justify-content-start">
                    <div className={`${styles.legendBulletPoint} rounded-circle mr-2`}
                      style={{ 
                        border: `1px solid ${color}`,
                        backgroundColor: color + '77' // add opacity to hex color, e.g. #223266 -> #22326677
                      }}></div>
                    <span className="mr-4">{label}</span>
                    <span className="ml-auto">{value}</span>
                  </li>             
                );
              })
            }
          </ul>
        </div>
      </CardBody>
    </Card>
  );
});