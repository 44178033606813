import { useCallback, useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

export const useDatePagination = (startDate: Date, days: number) => {
  const [dates, setDates] = useState<Date[]>([]);
  useEffect(() => setDates(buildDateInterval(startDate, days)), [startDate, days, setDates]);
  const onPrev = useCallback(() => setDates(dates.map((x) => addDays(x, -days))), [dates, days, setDates]);
  const onNext = useCallback(() => setDates(dates.map((x) => addDays(x, days))), [dates, days, setDates]);

  return useMemo(() => {
    return {
      dates,
      onPrev,
      onNext,
    };
  }, [dates, onPrev, onNext]);
};

export const useDateRangeFromPagination = (dates: Date[]) => {
  return useMemo(() => {
    return dates.map((x) => format(x, 'yyyy-MM-dd'));
  }, [dates]);
};

function buildDateInterval(startDate: Date, days: number) {
  return eachDayOfInterval({ start: startDate, end: addDays(startDate, days - 1) });
}
