import React, { useContext, useMemo, useState } from 'react';
import authStyles from '../../auth/Auth.module.scss';
import { Elements } from '@stripe/react-stripe-js';
import { StripeForm } from './StripeForm';
import { loadStripe } from '@stripe/stripe-js';
import { AuthContext } from '../../App';
import { useFetchClientSecret } from '../../api/hooks';

interface StripeFormContainerProps {
  onUpdateCard: (paymentMethodId: string) => void;
  loadingCard: boolean;
  displayInline: boolean;
}

export const StripeFormContainer = React.memo(
  ({ onUpdateCard, loadingCard, displayInline }: StripeFormContainerProps) => {
    const { userId } = useContext(AuthContext);
    const [stripeError, setStripeError] = useState<string>('');

    const clientSecretParams = useMemo(() => ({ userId }), [userId]);
    const clientSecretResponse = useFetchClientSecret(clientSecretParams);

    const stripe = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''), []);

    const onStripeError = (message: string) => {
      setStripeError(message);
    };

    return (
      <>
        {stripeError && <div className={`col-12 ${authStyles.errorMessage}`}> {stripeError}</div>}
        <Elements stripe={stripe}>
          <StripeForm
            clientSecret={clientSecretResponse.data.clientSecret}
            onSetPaymentMethod={onUpdateCard}
            onError={onStripeError}
            loading={loadingCard}
            displayInline={displayInline}
          />
        </Elements>
      </>
    );
  },
);
