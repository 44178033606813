import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthContext } from '../App';
import { useTimeSlots } from '../api/hooks';
import { OnboardingContainer } from './onboarding';
import { ScheduleContainer } from './schedule';
import { DashboardContainer } from './dashboard';
import { LocationsContainer } from './locations';
import { ShoppingCartContainer } from './shoppingCart';
import { ShoppingCartStore } from './shoppingCart/ShoppingCartStore';
import { MemberNav } from './nav/MemberNav';
import { PurchasesContainer } from './purchases';

export const MemberRoutePrefix = '/member';
export enum MemberRoutes {
  Root = '/member/dashboard',
  Dashboard = '/member/dashboard',
  Locations = '/member/schedule',
  Schedule = '/member/schedule/:locationId',
  Purchases = '/member/purchases',
  Cart = '/member/cart',
  Onboarding = '/member/onboarding',
}

export default React.memo(() => {
  useTimeSlots();

  const authContext = useContext(AuthContext);

  const isActive = authContext.memberStatus === 'active';

  let routes;
  if (authContext.memberStatus === 'pending') {
    routes = (
      <Switch>
        <Route exact path={MemberRoutes.Onboarding}>
          <OnboardingContainer />
        </Route>
        <Route>
          <Redirect to={MemberRoutes.Onboarding} />
        </Route>
      </Switch>
    );
  } else if (isActive) {
    routes = (
      <Switch>
        <Route exact path={MemberRoutes.Dashboard}>
          <DashboardContainer />
        </Route>
        <Route exact path={MemberRoutes.Locations}>
          <LocationsContainer />
        </Route>
        <Route exact path={MemberRoutes.Schedule}>
          <ScheduleContainer />
        </Route>
        <Route exact path={MemberRoutes.Purchases}>
          <PurchasesContainer />
        </Route>
        <Route exact path={MemberRoutes.Cart}>
          <ShoppingCartContainer />
        </Route>
        <Route>
          <Redirect to={MemberRoutes.Root} />
        </Route>
      </Switch>
    );
  } else {
    routes = <h1 className="text-danger">Inactive Account</h1>;
  }

  return (
    <ShoppingCartStore>
      <MemberNav showNavLinks={isActive} />
      <div className="container-fluid py-4 smd-routes-top-margin">{routes}</div>
    </ShoppingCartStore>
  );
});
