import React from 'react';

import { LocationsRow } from './LocationsRow';
import { Location } from '../../api/models';

export const LocationsList = React.memo<{ locations: Location[] }>((props) => {
  return (
    <div>
      {props.locations.map((location, index) => {
        const classNames = `
          ${index !== 0 ? 'border-top pt-4' : ''}          
          ${index !== props.locations.length - 1 ? 'pb-4' : ''}          
        `;
        return (
          <div key={location.id} className={classNames}>
            <LocationsRow location={location} />
          </div>
        );
      })}
    </div>
  );
});
