import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { User } from '../models';
import { useFetch } from './useFetch';

const NULL_USERS: User[] = [];

export const useFetchUsers = (params: any) => {
  const [request, setRequest] = useState<Promise<User[]>>();
  useEffect(() => setRequest(Api.users.list()), [params]);
  return useFetch<User[]>(request, NULL_USERS);
};
