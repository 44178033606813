import React from 'react';

import styles from './LocationAddressBlock.module.scss';
import { Location } from '../api/models';

export const LocationAddressBlock = React.memo(({ location }: { location: Location }) => {
  const address = [location.address, location.city, location.state, location.zip].filter((x) => !!x).join(', ');

  return (
    <>
      <h4 className={`${styles.name} mb-2`}>{location.name}</h4>
      <div className={`${styles.address} mb-2`}>{address}</div>
    </>
  );
});
