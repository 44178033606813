import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { User } from '../../api/models';
import { AdminRoutes } from '../Routes';
import { useTable, useSortBy, Column, usePagination } from 'react-table';
import { format } from 'date-fns';

import styles from './UsersTable.module.scss';

interface UserTableProps {
  columns: Array<Column>;
  data: Array<User>;
}

interface CellProps {
  value: string;
  row: {
    original: {
      lastName: string;
      id: number;
      status: string;
    };
  };
}

function Table({ columns, data }: UserTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: 'status', desc: true }],
        pageIndex: 0,
        pageSize: 15,
      },
    },
    useSortBy,
    usePagination,
  );

  const pageList = new Array(pageCount);
  for (let i = 1; i <= pageCount; i++) {
    pageList.push(i);
  }
  const pageButtons = pageList.map((item) => (
    <div
      key={item}
      onClick={() => gotoPage(item - 1)}
      className={`${pageIndex === item - 1 ? styles.active : ''} ${styles.paginationButton}`}
    >
      {item}
    </div>
  ));

  return (
    <>
      <table className={`table smd-table ${styles.table}`} {...getTableProps()}>
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className={`smd-column-header ${column.isSorted ? 'sorted' : ''}`}>
                    <div className={`sort-icon ${column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}`} />
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <ul className={styles.pagination}>
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className={`${styles.prev} ${styles.paginationButton}`}
        >
          <img src={require('../../assets/interaction/caret-right.svg')} alt="Previous Page" />
        </button>
        <div id="pageContainer" />
        {pageButtons}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className={`${styles.next} ${styles.paginationButton}`}
        >
          <img src={require('../../assets/interaction/caret-right.svg')} alt="Next Page" />
        </button>
      </ul>
    </>
  );
}

export function UsersTable(props: { users: User[] }) {
  const data = props.users;
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: ({ cell }: { cell: CellProps }) => (
          <Link to={generatePath(AdminRoutes.User, { userId: cell.row.original.id })}>
            {cell.value} {cell.row.original.lastName}
          </Link>
        ),
      },
      {
        Header: 'Practice',
        accessor: 'member.practiceName',
      },
      {
        Header: 'Location',
        accessor: 'member.licenseState',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Specialty',
        accessor: 'member.specialty',
      },
      {
        Header: 'Date Created',
        accessor: 'createdAt',
        Cell: ({ cell }: { cell: CellProps }) =>
          cell.value ? <div>{format(new Date(cell.value), 'MM/dd/yyyy')}</div> : null,
      },
    ],
    [],
  );

  return (
    <>
      <Table columns={columns} data={data} />
    </>
  );
}
