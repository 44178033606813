import { useEffect, useMemo, useState } from 'react';

import { Api } from '../../api';
import { GenericId, LocationAvailabilityBlock } from '../models';
import { useFetch } from './useFetch';
import { parseStartEndDateFromArray } from './fetchUtils';

interface FetchLocationAvailabilityParams {
  locationId: GenericId;
  startDate: string;
  endDate: string;
}

const NULL_AVAILABILITY: LocationAvailabilityBlock[] = [];

export const useFetchLocationAvailability = (params: FetchLocationAvailabilityParams) => {
  const [request, setRequest] = useState<Promise<LocationAvailabilityBlock[]>>();
  useEffect(() => {
    const { locationId, startDate, endDate } = params;
    if (!locationId || !startDate || !endDate) {
      return;
    }
    setRequest(Api.locationAvailability.list(locationId, { startDate, endDate }));
  }, [params]);
  return useFetch<LocationAvailabilityBlock[]>(request, NULL_AVAILABILITY);
};

export const useFetchLocationAvailabilityParams = (
  locationId: GenericId,
  dates: Date[],
): FetchLocationAvailabilityParams => {
  return useMemo(() => {
    return {
      ...parseStartEndDateFromArray(dates),
      locationId,
    };
  }, [locationId, dates]);
};
