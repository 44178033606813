import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';

import { storeJwt } from '../../api';
import { AuthContext } from '../../App';
import { AdminRoutes } from '../../admin';
import { MemberRoutes } from '../../member';
import { BasicInput, Card, WelcomeSpash } from '../../common';
import { isError, isLoading, isResolved, useRequestSignIn } from '../../api/hooks';
import { SignInSchema } from './formSchema';
import { AuthRoutes } from '../index';

import authStyles from '../Auth.module.scss';

interface SignInValues {
  email: string;
  password: string;
}

export const SignInContainer = React.memo(() => {
  const authContext = useContext(AuthContext);
  const { register, errors, handleSubmit } = useForm<SignInValues>({
    mode: 'onSubmit',
    resolver: yupResolver(SignInSchema, {
      abortEarly: false,
      recursive: true,
      strict: false,
    }),
    criteriaMode: 'all',
    shouldFocusError: true,
  });

  const [signInSuccess, setSignInSuccess] = useState(false);
  const [requestSignInParams, setRequestSignInParams] = useState({ email: '', password: '' });
  const signInResponse = useRequestSignIn(requestSignInParams);
  const onSignIn = useCallback((formValues: SignInValues) => {
    setRequestSignInParams({ ...formValues });
  }, []);

  useEffect(() => {
    if (signInSuccess || !isResolved(signInResponse)) {
      return;
    }
    setSignInSuccess(true);
    storeJwt({ ...signInResponse.data });
    authContext.refresh();
  }, [signInSuccess, authContext, signInResponse]);

  if (authContext.authenticated) {
    if (authContext.role === 'member') {
      return <Redirect to={MemberRoutes.Root} />;
    }

    if (authContext.role === 'admin') {
      return <Redirect to={AdminRoutes.Root} />;
    }
  }

  const error = isError(signInResponse);
  const loading = isLoading(signInResponse);

  return (
    <div className="row">
      <div className="col text-center d-md-block d-none">
        <WelcomeSpash />
      </div>
      <div className="col-12 col-md">
        <Card className="col" cardTitle="Login to your account">
          {error && (
            <div className={`col-12 ${authStyles.errorMessage}`}>
              Hmm…That email/password combo is incorrect. Try again or <a href={AuthRoutes.ResetPassword}>reset.</a>
            </div>
          )}
          <form onSubmit={handleSubmit(onSignIn)}>
            <BasicInput
              id="email"
              label="Email Address"
              errorMessage={errors.email?.message}
              name="email"
              disabled={loading}
              ref={register}
            />
            <BasicInput
              id="password"
              type="password"
              label="Password"
              errorMessage={errors.password?.message}
              name="password"
              disabled={loading}
              ref={register}
            />
            <div className="d-flex justify-content-between mt-4">
              <a href={AuthRoutes.ForgotPassword} className={authStyles.underlinedLink}>
                Forgot Password?
              </a>
              <a href="https://sharemd.com/suites#contact" className={authStyles.underlinedLink}>
                Don't have an account?
              </a>
            </div>
            <div className="d-flex justify-content-center mt-4 mb-3">
              <button className="btn btn-secondary" type="submit" disabled={loading}>
                Login
              </button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
});
