import { get, post, put, destroy } from './client';
import {
  JWT,
  Location,
  LocationAvailabilityBlock,
  LocationBooking,
  MediaCategory,
  MediaResource,
  TimeSlot,
  User,
  UserBooking,
  Message,
  ClientSecret,
  PaymentMethodId,
  UpdatedUser,
  Subscription,
  BookingPreviewRequest,
  UserStatus,
  Purchase,
  GenericId,
  BookingPreview,
  KPI,
  MembershipAgreementTemplate,
} from './models';

export const Api = {
  auth: {
    logIn: (email: string, password: string) => {
      return post<JWT>('/auth/login', { email, password });
    },
    refresh: (refreshToken: string) => {
      return post<JWT>('/auth/refresh', { refreshToken });
    },
    confirmInvite: (email: string, inviteToken: string, password: string, passwordConfirmation: string) => {
      return post<JWT>('/auth/confirm-invite', {
        email,
        invite: inviteToken,
        password: password,
        confirmPassword: passwordConfirmation,
      });
    },
    resetPassword: (email: string, tmpPassword: string, password: string, passwordConfirmation: string) => {
      return post<JWT>('/auth/reset-password', {
        email,
        tmpPassword: tmpPassword,
        password: password,
        confirmPassword: passwordConfirmation,
      });
    },
    resendInvite: (email: string) => {
      return post<null>('/auth/resend-invite', { email });
    },
    forgotPassword: (email: string) => {
      return post<Message>('/auth/forgot-password', { email });
    },
    signUp: (params: {
      firstName: string;
      lastName: string;
      email: string;
      subscriptionId: number;
      locationId?: number;
      roomId?: number;
    }) => {
      return post<User>('/auth/sign-up', params);
    },
  },
  membershipAgreement: {
    fetch: () => {
      return get<MembershipAgreementTemplate>('/membership-agreement');
    },
  },
  users: {
    list: () => {
      return get<User[]>(`/user`);
    },
    get: (userId: GenericId) => {
      return get<User>(`/user/${userId}`);
    },
    clientSecret: (userId: GenericId) => {
      return get<ClientSecret>(`/user/${userId}/client-secret`);
    },
    updatePaymentMethod: (userId: GenericId, stripePreferredPaymentMethodId: string) => {
      return put<PaymentMethodId>(`/user/${userId}/payment`, { stripePreferredPaymentMethodId });
    },
    updateUser: (user: UpdatedUser) => {
      return put<User>(`/user/${user.id}`, { ...user });
    },
    status: (userId: GenericId, status: UserStatus) => {
      return put<User>(`/user/${userId}/status`, { status });
    },
    purchasesDownload: (userId: GenericId, params: { startDate: string; endDate: string }) => {
      return get<string>(`/user/${userId}/purchases/download`, {
        params,
      });
    },
  },
  userPurchases: {
    list: (userId: GenericId) => {
      return get<Purchase[]>(`/user/${userId}/purchases`);
    },
  },
  userBookings: {
    list: (userId: GenericId, params: { startDate: string; endDate: string }) => {
      return get<UserBooking[]>(`/user/${userId}/bookings`, {
        params,
      });
    },
  },
  timeSlots: {
    list: () => {
      return get<TimeSlot[]>('/time-slot');
    },
  },
  locations: {
    list: () => {
      return get<Location[]>('/location');
    },
    get: (locationId: GenericId) => {
      return get<Location>(`/location/${locationId}`);
    },
  },
  locationAvailability: {
    list: (locationId: GenericId, params: { startDate: string; endDate: string }) => {
      return get<LocationAvailabilityBlock[]>(`/location/${locationId}/availability`, {
        params,
      });
    },
  },
  locationBookings: {
    list: (locationId: GenericId, params: { startDate: string; endDate: string }) => {
      return get<LocationBooking[]>(`/location/${locationId}/booking`, {
        params,
      });
    },
  },
  bookings: {
    create: (bookings: BookingPreviewRequest[], checksum: string) => {
      return post<Purchase>(`/booking`, {
        bookings,
        checksum,
      });
    },
    preview: (bookings: BookingPreviewRequest[]) => {
      return post<BookingPreview>(`/booking/preview`, { bookings });
    },
    delete: (bookingId: GenericId) => {
      return destroy(`/booking/${bookingId}`);
    },
  },
  subscriptions: {
    list: () => {
      return get<Subscription[]>('/subscription');
    },
  },
  kpi: {
    get: () => {
      return get<KPI>('/kpi');
    },
  },
  media: {
    upload: (userId: GenericId, category: MediaCategory, file: File) => {
      const formData = new FormData();
      formData.append('user_id', `${userId}`);
      formData.append('category', category);
      formData.append('resource', file);

      return post<MediaResource>('/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
  },
};
