import React, { useContext } from 'react';

import styles from './BookingModal.module.scss';
import { ShoppingCartActionsContext, ShoppingCartItemsContext } from '../../shoppingCart/ShoppingCartStore';
import { LocationAvailabilityBlock, Location, LocationRoom, TimeSlot } from '../../../api/models';
import { RoomSummaryBlock } from '../../../common';

interface BookingModalRowProps {
  date: string;
  index: number;
  location: Location;
  room: LocationRoom;
  timeSlot: TimeSlot;
  availability?: LocationAvailabilityBlock;
}

export const BookingModalRow = React.memo((props: BookingModalRowProps) => {
  const { index, date, location, room, timeSlot, availability } = props;

  const shoppingCart = useContext(ShoppingCartItemsContext);
  const shoppingCartActions = useContext(ShoppingCartActionsContext);

  const inShoppingCart = shoppingCart.find(
    (x) => x.date === date && x.location.id === location.id && x.room.id === room.id && x.timeSlot.id === timeSlot?.id,
  );

  let actionColumn;
  if (inShoppingCart) {
    actionColumn = (
      <>
        <div className={`${styles.statusInCart} mb-2`}>Added to Cart!</div>
        <button
          type="button"
          className="btn btn-block btn-light"
          onClick={() => {
            shoppingCartActions.remove(inShoppingCart);
          }}
        >
          Remove
        </button>
      </>
    );
  } else if (timeSlot && availability && availability.status === 'available') {
    actionColumn = (
      <>
        <div className={`${styles.statusAvailable} mb-2`}>Available!</div>
        <button
          type="button"
          className="btn btn-block btn-primary"
          onClick={() => {
            shoppingCartActions.add({
              date,
              location,
              room,
              timeSlot,
            });
          }}
        >
          Add to Cart
        </button>
      </>
    );
  } else {
    actionColumn = <div className={`${styles.statusUnavailable} mb-2`}>Unavailable</div>;
  }

  return (
    <div key={room.id} className={`py-3 ${index > 0 ? 'border-top' : ''}`}>
      <div className="row align-items-center">
        <div className="col-12 col-lg">
          <RoomSummaryBlock room={room} />
        </div>
        <div className="col-12 col-lg-auto">
          <div className={styles.actionColumn}>
            <div className="d-flex flex-column align-items-center">{actionColumn}</div>
          </div>
        </div>
      </div>
    </div>
  );
});
