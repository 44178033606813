import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { UserStatus } from '../models';
import { useFetch } from './useFetch';

const NULL_RESPONSE = {};

export const useRequestUserStatus = (params: { userId: number | string; status?: UserStatus }) => {
  const [request, setRequest] = useState<Promise<any>>();
  useEffect(() => {
    if (!params.userId || !params.status) {
      return;
    }
    setRequest(Api.users.status(params.userId, params.status));
  }, [params]);
  return useFetch<any>(request, NULL_RESPONSE);
};
