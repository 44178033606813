import React, { useMemo } from 'react';

import styles from './BasicInput.module.scss';

interface FormControlProps {
  label: string;
  noGroupMargin?: boolean;
  errorMessage?: string;
}

type InputControlProps = FormControlProps & React.HTMLProps<HTMLInputElement>;
type SelectControlProps = FormControlProps & React.HTMLProps<HTMLSelectElement>;

export const BasicInput = React.memo(
  React.forwardRef<HTMLInputElement, InputControlProps>((props, ref) => {
    const { label, noGroupMargin, errorMessage, ...restProps } = props;
    const { id, className, ...inputProps } = restProps;
    const classNames = useInputClasses(!!errorMessage, className);
    return (
      <div className={`form-group ${noGroupMargin ? 'm-0' : ''}`}>
        <label htmlFor={id} className={styles.basicInputLabel}>
          {label}
        </label>
        <input id={id} className={classNames} {...inputProps} ref={ref} />
        {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
      </div>
    );
  }),
);

export const BasicSelect = React.memo(
  React.forwardRef<HTMLSelectElement, SelectControlProps>((props, ref) => {
    const { label, noGroupMargin, errorMessage, ...restProps } = props;
    const { id, className, ...inputProps } = restProps;
    const classNames = useSelectClasses(!!errorMessage, className);
    return (
      <div className={`form-group ${noGroupMargin ? 'm-0' : ''}`}>
        <label htmlFor={id} className={styles.basicInputLabel}>
          {label}
        </label>
        <select id={id} className={classNames} {...inputProps} ref={ref} />
        {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
      </div>
    );
  }),
);

export const BasicInlineCheckbox = React.memo(
  React.forwardRef<HTMLInputElement, InputControlProps>((props, ref) => {
    const { label, noGroupMargin, errorMessage, ...restProps } = props;
    const { id, className, ...inputProps } = restProps;
    const classNames = useInlineCheckboxClasses(!!errorMessage, className);
    return (
      <div className={`form-group ${noGroupMargin ? 'm-0' : ''}`}>
        <div className="custom-control custom-checkbox">
          <input type="checkbox" id={id} className={classNames} {...inputProps} ref={ref} />
          <label className="custom-control-label" htmlFor={id}>
            {label}
          </label>
          {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
        </div>
      </div>
    );
  }),
);

function generateClassNameHook(baseClassName: string) {
  return (hasError: boolean, className?: string) => {
    return useMemo(() => {
      // eslint-disable-next-line eqeqeq
      return [baseClassName, hasError ? 'is-invalid' : undefined, className].filter((x) => x != undefined).join(' ');
    }, [hasError, className]);
  };
}

const useInputClasses = generateClassNameHook('form-control');
const useInlineCheckboxClasses = generateClassNameHook('custom-control-input');
const useSelectClasses = generateClassNameHook('custom-select');
