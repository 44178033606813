import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { useFetch } from './useFetch';
import { GenericId } from '../models';

const NULL_RESPONSE = {};

export const useRequestPreferredPaymentId = (params: { userId: GenericId; stripePreferredPaymentMethodId: string }) => {
  const [request, setRequest] = useState<Promise<any>>();
  useEffect(() => {
    if (!params.userId || !params.stripePreferredPaymentMethodId) {
      return;
    }
    setRequest(Api.users.updatePaymentMethod(params.userId, params.stripePreferredPaymentMethodId));
  }, [params]);
  return useFetch<any>(request, NULL_RESPONSE);
};
