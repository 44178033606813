import React, { ReactNode, useState, useMemo, useEffect } from 'react';

import { Location, LocationRoom, TimeSlot } from '../../api/models';

export interface ShoppingCartItem {
  date: string;
  location: Location;
  room: LocationRoom;
  timeSlot: TimeSlot;
}

export interface ShoppingCartActions {
  add: (item: ShoppingCartItem) => void;
  remove: (item: ShoppingCartItem) => void;
  clear: () => void;
}

export const ShoppingCartItemsContext = React.createContext<ShoppingCartItem[]>([]);
export const ShoppingCartActionsContext = React.createContext<ShoppingCartActions>({
  add: () => {},
  remove: () => {},
  clear: () => {},
});

export const ShoppingCartStore = React.memo((props: { children: ReactNode }) => {
  // TODO: Better local storage
  const [items, setItems] = useState<ShoppingCartItem[]>(JSON.parse(localStorage.getItem('smdCart') || '[]'));

  const actions = useMemo(() => {
    return {
      add: (item: ShoppingCartItem) => {
        setItems([...items, item]);
      },
      remove: (item: ShoppingCartItem) => {
        setItems(items.filter((x) => x !== item));
      },
      clear: () => {
        setItems([]);
      },
    };
  }, [items]);

  // TODO: Better local storage
  useEffect(() => localStorage.setItem('smdCart', JSON.stringify(items)), [items]);

  return (
    <ShoppingCartActionsContext.Provider value={actions}>
      <ShoppingCartItemsContext.Provider value={items}>{props.children}</ShoppingCartItemsContext.Provider>
    </ShoppingCartActionsContext.Provider>
  );
});
