import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { JWT } from '../models';
import { useFetch } from './useFetch';

interface RequestResetPasswordParams {
  email: string;
  tmpPassword: string;
  password: string;
  passwordConfirmation: string;
}

const NULL_JWT: JWT = {
  userId: -1,
  token: '',
  refreshToken: '',
  expiration: 0,
};

export const useRequestResetPassword = (params: RequestResetPasswordParams) => {
  const [request, setRequest] = useState<Promise<JWT>>();
  useEffect(() => {
    const { email, tmpPassword, password, passwordConfirmation } = params;
    if (!email || !tmpPassword || !password || !passwordConfirmation) {
      return;
    }
    setRequest(Api.auth.resetPassword(email, tmpPassword, password, passwordConfirmation));
  }, [params]);
  return useFetch<JWT>(request, NULL_JWT);
};
