import React from 'react';
import { Card } from '../../layout';

import styles from './Onboarding.module.scss';
import buildings from '../../assets/illustrations/buildings.svg';

export const HangTight = React.memo(() => {
  return (
    <div className="col-12 col-lg-10">
      <Card>
        <div className="d-flex flex-column align-items-center">
          <div className="col-6 text-center">
            <img src={buildings} alt="Welcome to ShareMD" className="mb-3" />
            <h1>Hang Tight</h1>
            <div className="mb-4">
              You're almost there! Your information has been received and will be approved shortly. Once approved, you
              will enjoy the following benefits:
            </div>
          </div>
          <div className="d-flex justify-content-between mb-5">
            <div className={`col-3 text-center ${styles.hangTightStep}`}>
              <div className={styles.step}>1.</div>
              <div>Access to high end medical exam rooms and office spaces</div>
            </div>
            <div className={`col-3 text-center ${styles.hangTightStep}`}>
              <div className={styles.step}>2.</div>
              <div>The ability to customize your schedule with access to multiple locations</div>
            </div>
            <div className={`col-3 text-center ${styles.hangTightStep}`}>
              <div className={styles.step}>3.</div>
              <div>Transparent pricing with no long term leases</div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
});
