import React from 'react';

import footerLogo from '../assets/logos/sharemd-icon-logo.svg';
import footerStyles from './Footer.module.scss';

export const Footer = React.memo(() => {
  const columnClasses = 'col-12 col-md-auto mb-2 mb-md-0 text-center text-md-left';
  const copyrightYear = new Date().getFullYear();

  return (
    <footer className={footerStyles.footerContainer}>
      <div className="container-fluid">
        <div className={footerStyles.footer}>
          <div className="row align-items-center">
            <div className={columnClasses}>
              <img src={footerLogo} alt="ShareMD" />
            </div>
            <div className={columnClasses}>
              <span className={footerStyles.copyright}>&copy; {copyrightYear} ShareMD, Inc. All rights reserved.</span>
            </div>
            <div className={columnClasses}>
              <a href="https://sharemd.com" target="_blank" rel="noopener noreferrer">
                visit sharemd.com
              </a>
            </div>
            <div className="col-12 col-md" />
            <div className={columnClasses}>
              <div className="text-nowrap d-flex align-items-center justify-content-center">
                <a href="http://sharemd.com/suites/terms" target="_blank" rel="noopener noreferrer">
                  Terms of Use
                </a>
                <span className={footerStyles.divider} />
                <a href="http://sharemd.com/suites/privacy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});
