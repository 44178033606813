import React from 'react';

import buildings from '../assets/illustrations/buildings.svg';

export const WelcomeSpash = React.memo(() => (
  <>
    <img src={buildings} alt="Welcome to ShareMD" className="mb-3" />
    <h1>Welcome to ShareMD!</h1>
    <div className="mb-4">
      At ShareMD, we believe that doctors deserve to practice medicine. That's why we provide shared medical suites that
      scale with your practice.
    </div>
  </>
));
