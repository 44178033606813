import React from 'react';
import { Link, useRouteMatch, NavLink } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';

import { AdminRoutes } from '../index';
import { UserDropdown } from '../../common';

export const AdminNav = React.memo(() => {
  return (
    <Navbar bg="light" expand="sm" className="bg-white" fixed="top">
      <div className="container-fluid">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand>
          <Link className="navbar-brand d-flex" to={AdminRoutes.Root}>
            <img src={require('../../assets/logos/sharemd-logo.svg')} alt="ShareMD" width="128" />
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse id="admin-navbar-collapse">
          <Nav className="ml-auto">
            <Nav.Item className={`my-sm-0 my-2 ${useRouteMatch(AdminRoutes.Dashboard) ? 'active' : ''}`}>
              <NavLink to={AdminRoutes.Dashboard} className="nav-link">
                Dashboard
              </NavLink>
            </Nav.Item>
            <Nav.Item className={`my-sm-0 my-2 ${useRouteMatch(AdminRoutes.Users) ? 'active' : ''}`}>
              <NavLink to={AdminRoutes.Users} className="nav-link">
                Doctor Member List
              </NavLink>
            </Nav.Item>
            <Nav.Item className={`my-sm-0 my-2 ${useRouteMatch(AdminRoutes.Locations) ? 'active' : ''}`}>
              <NavLink to={AdminRoutes.Locations} className="nav-link">
                Locations List
              </NavLink>
            </Nav.Item>
          </Nav>
          <UserDropdown />
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
});
