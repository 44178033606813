import React, { useContext, useMemo } from 'react';

import styles from './CalendarBlock.module.scss';
import { CalendarBlockProps } from '../../calendar';
import { TimeSlot } from '../../api/models';
import { ShoppingCartItemsContext } from '../shoppingCart/ShoppingCartStore';

export interface CalendarBlockData {
  locationId: number;
  availability: {
    [key: string]: {
      unavailable: number;
      available: number;
      booked: number;
    };
  };
}

export interface CustomCalendarBlockProps {
  onSelect: (date: string, timeSlot: TimeSlot) => void;
}

export const CalendarBlock = React.memo((props: CalendarBlockProps<CalendarBlockData, CustomCalendarBlockProps>) => {
  const { date, timeSlot, data } = props;
  const key = useMemo(() => `${date}-${timeSlot.id}`, [date, timeSlot]);

  const shoppingCart = useContext(ShoppingCartItemsContext);

  let availabilities = data.availability[key] || { available: 0, booked: 0, unavailable: 0 };
  if (availabilities.available > 0) {
    const countInShoppingCart = shoppingCart.filter(
      (x) => x.date === date && x.timeSlot.id === timeSlot.id && data.locationId == x.location.id,
    ).length;
    const adjustedAvailableCount = availabilities.available - countInShoppingCart;
    return (
      <div
        className={`py-2 px-3 d-flex flex-column flex-fill justify-content-center text-center ${
          countInShoppingCart > 0 ? styles.inCart : ''
        }`}
      >
        <div className={styles.availableCount}>
          {adjustedAvailableCount} room{adjustedAvailableCount !== 1 ? 's' : ''} available
        </div>
        <div className="mt-2">
          <button
            type="button"
            className="btn btn-primary px-3"
            onClick={() => props.cellProps.onSelect(date, timeSlot)}
          >
            Book Now!
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`${styles.bookedBlock} py-2 px-3 d-flex flex-column flex-fill`}>
        <em>{availabilities.booked > 0 ? 'Booked' : 'Unavailable'}</em>
      </div>
    );
  }
});
