import React from 'react';

import styles from './KPI.module.scss';

export const KPI = React.memo<{ label: string, value: string }>(({ label, value }) => {
  return (
    <div className={`${styles.kpiWrapper} bg-light rounded-circle border border-dark d-flex flex-column align-items-center justify-content-between text-center py-4 m-sm-2 m-1`}>
      <div className={styles.kpiValue}>{value}</div>
      <div className={`${styles.kpiLabel} w-75`}>{label}</div>
    </div>
  );
});