import React from 'react';

import { LocationRoom } from '../api/models';
import styles from './RoomSummaryBlock.module.scss';

export const RoomSummaryBlock = React.memo((props: { room: LocationRoom }) => {
  const { room } = props;

  return (
    <div className="row">
      <div className="col-12 col-lg-auto">
        <div
          className={styles.roomImage}
          style={{ backgroundImage: room.image ? `url(${room.image.src})` : undefined }}
        />
      </div>
      <div className="col-12 col-lg">
        <div className={styles.roomName}>{room.name}</div>
        <div className={styles.roomSqft}>{room.squareFeet} sqft</div>
        <div>{room.details}</div>
      </div>
    </div>
  );
});
