import React from 'react';

import { LocationsList } from './LocationsList';
import { Card, CardBody, CardTitle } from '../../layout';
import { SimpleStatCard } from '../../common';
import { isLoading, isResolved, useFetchLocations, useFetchKPI } from '../../api/hooks';

export const LocationsContainer = React.memo(() => {
  const locationsResponse = useFetchLocations();
  const kpiResponse = useFetchKPI();

  let kpi = kpiResponse.data;
  let content = null;
  if (isLoading(locationsResponse)) {
    content = (
      <div className="text-center py-5">
        <em>Loading</em>
      </div>
    );
  } else if (isResolved(locationsResponse)) {
    content = <LocationsList locations={locationsResponse.data} />;
  }

  let totalSlots = kpi.timeSlotsAvailable + kpi.timeSlotsBooked;
  let availablePercent = totalSlots ? Math.round((kpi.timeSlotsAvailable / totalSlots) * 100) : 0;
  let bookedPercent = totalSlots ? Math.round((kpi.timeSlotsBooked / totalSlots) * 100) : 0;

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-3 mb-4 d-flex flex-column">
          <SimpleStatCard label="Total Locations" value={kpi.totalLocations || '-'} />
        </div>

        <div className="col-12 col-sm-6 col-lg-3 mb-4 d-flex flex-column">
          <SimpleStatCard label="Total Rooms" value={kpi.totalRooms || '-'} />
        </div>

        <div className="col-12 col-sm-6 col-lg-3 mb-4 d-flex flex-column">
          <SimpleStatCard
            label="Time Slots Available"
            value={kpi.timeSlotsAvailable || '-'}
            additional={`${availablePercent}% of Total`}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-3 mb-4 d-flex flex-column">
          <SimpleStatCard
            label="Time Slots Booked"
            value={kpi.timeSlotsBooked || '-'}
            additional={`${bookedPercent}% of Total`}
          />
        </div>
      </div>

      <Card>
        <CardBody borderBottom thin>
          <CardTitle>Location List</CardTitle>
        </CardBody>
        <CardBody>{content}</CardBody>
      </Card>
    </>
  );
});
