import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { isError, isLoading, isResolved, useRequestForgotPassword } from '../../api/hooks';

import { BasicInput, Card, WelcomeSpash } from '../../common';
import { AuthRoutes } from '../Routes';
import { ForgotPasswordSchema } from './formSchema';

import authStyles from '../Auth.module.scss';

interface ForgotPasswordValues {
  email: string;
}

export const ForgotPasswordContainer = React.memo(() => {
  const { register, errors, handleSubmit } = useForm<ForgotPasswordValues>({
    resolver: yupResolver(ForgotPasswordSchema, {
      abortEarly: false,
      recursive: true,
      strict: false,
    }),
  });

  const [requestForgotPasswordParams, setRequestForgotPasswordParams] = useState({ email: '' });
  const forgotPasswordResponse = useRequestForgotPassword(requestForgotPasswordParams);
  const onForgotPassword = useCallback((formValues: ForgotPasswordValues) => {
    setRequestForgotPasswordParams(formValues);
  }, []);

  const resolved = isResolved(forgotPasswordResponse);
  const loading = isLoading(forgotPasswordResponse);
  const error = isError(forgotPasswordResponse);

  return (
    <div className="row">
      <div className="col text-center d-md-block d-none">
        <WelcomeSpash />
      </div>
      <div className="col-12 col-md">
        <Card className="col" cardTitle="Forgot your password?">
          {resolved && <p>{forgotPasswordResponse.data.message}</p>}
          {!resolved && <p className="mb-4">Enter your email address and we'll send a link to reset your password.</p>}
          {error && (
            <div className={`col-12 mb-2 ${authStyles.errorMessage}`}>
              Hmm…There was an error: {forgotPasswordResponse.error?.message}
            </div>
          )}
          <form onSubmit={handleSubmit(onForgotPassword)}>
            <BasicInput
              id="email"
              label="Email Address"
              errorMessage={errors.email?.message}
              name="email"
              disabled={loading}
              ref={register}
            />
            <div className="d-flex justify-content-center mt-4 mb-3">
              <button className="btn btn-secondary" type="submit" disabled={loading}>
                Send Reset Instructions
              </button>
            </div>
          </form>
          <div className="d-flex justify-content-center mt-4 mb-3">
            <a href={AuthRoutes.SignIn} className={authStyles.underlinedLink}>
              Back to Sign In
            </a>
          </div>
        </Card>
      </div>
    </div>
  );
});
