import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';

import { AuthContext } from '../../App';
import {
  isError,
  isLoading,
  isResolved,
  useFetchBookingPreview,
  useRequestBooking,
  useRequestPreferredPaymentId,
} from '../../api/hooks';
import { ShoppingCart } from './ShoppingCart';
import { ShoppingCartActionsContext, ShoppingCartItemsContext } from './ShoppingCartStore';
import { ShoppingCartSuccess } from './ShoppingCartSuccess';
import { ShoppingCartEmpty } from './ShoppingCartEmpty';

export const ShoppingCartContainer = React.memo(() => {
  const { userId, refresh } = useContext(AuthContext);
  const shoppingCart = useContext(ShoppingCartItemsContext);
  const shoppingCartActions = useContext(ShoppingCartActionsContext);

  // Keep fetching shopping cart preview while the shopping cart updates
  // Use this trigger to trigger another preview call (after a booking call has failed and you need the new state)
  const [previewTrigger, setPreviewTrigger] = useState<Date>();
  const bookingPreviewParams = useMemo(() => {
    return shoppingCart.map((x) => {
      return {
        locationId: x.location.id,
        roomId: x.room.id,
        timeSlotId: x.timeSlot.id,
        date: x.date,
        userId,
      };
    });
  }, [userId, shoppingCart, previewTrigger]);
  const bookingPreviewResponse = useFetchBookingPreview(bookingPreviewParams);
  // Keep the preview checksum around for convenience, need to pass it to the actual purchase
  const lastChecksum = useMemo(() => bookingPreviewResponse.data.checksum, [bookingPreviewResponse]);
  // Keep a boolean that tracks whether or not any of the bookings need to be removed before purchase
  const conflicts = useMemo(
    () => bookingPreviewResponse.data.bookings.some((x) => x.status === 'unavailable' || x.status === 'booked'),
    [bookingPreviewResponse],
  );

  // Actually make the final purchase call using the booking data and the last received checksum
  // Use this trigger to trigger the final booking call
  const [purchaseTrigger, setPurchaseTrigger] = useState<Date>();
  const finalBookingParams = useMemo(() => {
    if (!purchaseTrigger || lastChecksum.length === 0) {
      return {
        checksum: '',
        bookings: [],
      };
    }
    return {
      checksum: lastChecksum,
      bookings: bookingPreviewParams,
    };
  }, [bookingPreviewParams, lastChecksum, purchaseTrigger]);
  const bookingResponse = useRequestBooking(finalBookingParams);
  useEffect(() => {
    // If we ever reach an error, clear out the purchase trigger
    // and update the preview trigger to refresh the cart status
    if (isError(bookingResponse)) {
      setPurchaseTrigger(undefined);
      setPreviewTrigger(new Date());
    }
  }, [bookingResponse]);

  const onSubmitBooking = useCallback(() => setPurchaseTrigger(new Date()), []);

  const success = isResolved(bookingResponse);

  // Clear out the cart on success;
  const [clearedCart, setClearedCart] = useState(false);
  useEffect(() => {
    if (!clearedCart && success) {
      shoppingCartActions.clear();
      setClearedCart(true);
    }
  }, [shoppingCartActions, clearedCart, success]);

  const loading = [bookingResponse, bookingPreviewResponse].some(isLoading);

  const [requestPaymentMethodId, setRequestPaymentMethodId] = useState<string>('');
  const preferredPaymentIdParams = useMemo(
    () => ({ stripePreferredPaymentMethodId: requestPaymentMethodId, userId: userId }),
    [userId, requestPaymentMethodId],
  );
  const responsePreferredPaymentId = useRequestPreferredPaymentId(preferredPaymentIdParams);
  useEffect(() => {
    refresh();
  }, [responsePreferredPaymentId]);
  const loadingCard = isLoading(responsePreferredPaymentId);

  let content;
  if (success) {
    content = <ShoppingCartSuccess />;
  } else if (shoppingCart.length === 0) {
    content = <ShoppingCartEmpty />;
  } else {
    content = (
      <>
        <ShoppingCart
          shoppingCart={shoppingCart}
          preview={bookingPreviewResponse.data.bookings}
          monthlyUsage={bookingPreviewResponse.data.monthlyUsage}
          loading={loading}
          conflicts={conflicts}
          onSubmit={onSubmitBooking}
          onUpdateCard={setRequestPaymentMethodId}
          loadingCard={loadingCard}
        />
      </>
    );
  }

  return (
    <div>
      <h3 className="mb-4">Your Cart</h3>
      {content}
    </div>
  );
});
