import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { BookingPreview, BookingPreviewRequest } from '../models';
import { useFetch } from './useFetch';

type FetchBookingPreviewParams = BookingPreviewRequest[];

const NULL_PREVIEWS: BookingPreview = {
  checksum: '',
  bookings: [],
  monthlyUsage: [],
};

export const useFetchBookingPreview = (params: FetchBookingPreviewParams) => {
  const [request, setRequest] = useState<Promise<BookingPreview>>();
  useEffect(() => {
    if (params.length === 0) {
      return;
    }
    setRequest(Api.bookings.preview(params));
  }, [params]);
  return useFetch<BookingPreview>(request, NULL_PREVIEWS);
};
