import React, { useState, useEffect } from 'react';

import { GenericId, LocationRoom } from '../../../api/models';
import { RoomSummaryBlock } from '../../../common';

import styles from './CancelModal.module.scss';

interface CancelModalRowProps {
  index: number;
  bookingId: number;
  room: LocationRoom;
  cancellable: boolean;
  pendingCancellation: boolean;
  recentlyCancelled: boolean;
  disabled: boolean;
  onCancel: (bookingId: GenericId) => void;
  onCancelConfirm: (bookingId: GenericId) => void;
}

export const CancelModalRow = React.memo((props: CancelModalRowProps) => {
  const { index, bookingId, room, cancellable, pendingCancellation, recentlyCancelled, disabled } = props;
  const { onCancel, onCancelConfirm } = props;

  // Disable the cancel button for a moment after their initial click to prevent accidental cancellations
  const [justClickedCancel, setJustClickedCancel] = useState(false);
  useEffect(() => {
    if (pendingCancellation) {
      setJustClickedCancel(true);
    }
    const clearToken = setInterval(() => {
      setJustClickedCancel(false);
    }, 250);
    return () => clearInterval(clearToken);
  }, [pendingCancellation]);

  let actionColumn;
  if (recentlyCancelled) {
    actionColumn = <span className="text-muted">Cancelled</span>;
  } else if (cancellable) {
    const buttonProps = {
      onClick: pendingCancellation ? () => onCancelConfirm(bookingId) : () => onCancel(bookingId),
      children: pendingCancellation ? 'Confirm' : 'Cancel',
      className: `btn btn-${justClickedCancel ? 'primary' : pendingCancellation ? 'danger' : 'primary'}`,
      disabled: justClickedCancel || disabled,
    };
    actionColumn = <button type="button" {...buttonProps} />;
  } else {
    actionColumn = <span className="text-muted">Unable to cancel</span>;
  }

  return (
    <div key={room.id} className={`py-3 ${index > 0 ? 'border-top' : ''}`}>
      <div className="row align-items-center">
        <div className="col-12 col-lg">
          <RoomSummaryBlock room={room} />
        </div>
        <div className="col-12 col-lg-auto">
          <div className={styles.actionColumn}>
            <div className="d-flex flex-column align-items-center">{actionColumn}</div>
          </div>
        </div>
      </div>
    </div>
  );
});
