import React from 'react';
import { Link, useRouteMatch, NavLink } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';

import { MemberRoutes } from '../index';
import { ShoppingCartLink } from '../shoppingCart';
import { UserDropdown } from '../../common';

interface MemberNavProps {
  showNavLinks: boolean;
}

export const MemberNav = React.memo(({ showNavLinks }: MemberNavProps) => {
  return (
    <Navbar bg="light" expand="sm" className="bg-white" fixed="top">
      <div className="container-fluid">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand>
          <Link className="navbar-brand d-flex" to={MemberRoutes.Root}>
            <img src={require('../../assets/logos/sharemd-logo.svg')} alt="ShareMD" width="128" />
          </Link>
        </Navbar.Brand>
        <div className="d-sm-none d-flex">
          <ShoppingCartLink />
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          {showNavLinks ? (
            <>
              <Nav className="navbar-nav ml-auto">
                <Nav.Item className={`my-sm-0 my-2 ${useRouteMatch(MemberRoutes.Dashboard) ? 'active' : ''}`}>
                  <NavLink to={MemberRoutes.Dashboard} className="nav-link">
                    Schedule
                  </NavLink>
                </Nav.Item>
                <Nav.Item className={`my-sm-0 my-2 ${useRouteMatch(MemberRoutes.Purchases) ? 'active' : ''}`}>
                  <NavLink to={MemberRoutes.Purchases} className="nav-link">
                    Purchases
                  </NavLink>
                </Nav.Item>
              </Nav>
              <div className="d-none d-sm-inline">
                <ShoppingCartLink />
              </div>
              <UserDropdown />
            </>
          ) : (
            <div className="ml-auto">
              <UserDropdown />
            </div>
          )}
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
});
