import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import styles from './ShoppingCartMonthlyUsage.module.scss';
import { BookingPreviewMonthlyUsage } from '../../api/models';
import { ProgressBar } from '../../common';

interface ShoppingCartMonthlyUsageProps {
  usage: BookingPreviewMonthlyUsage;
}

export const ShoppingCartMonthlyUsage = React.memo(({ usage }: ShoppingCartMonthlyUsageProps) => {
  const monthLabel = format(parseISO(usage.month), 'LLLL');
  const booked = Math.min(usage.bookingsThisMonth, usage.bookingsIncluded);
  const remaining = Math.max(0, usage.bookingsIncluded - usage.bookingsThisMonth);
  const percentageUsed = Math.max(0, Math.min(1, booked / usage.bookingsIncluded));
  const remainingAfterPurchase = remaining - usage.bookingsThisPurchase;
  return (
    <>
      <div className={styles.monthLabel}>{monthLabel} Subscription Status</div>
      <div className={`${styles.progressBarWrapper} my-1`}>
        <ProgressBar value={percentageUsed} />
      </div>
      <div className={styles.usageLabel}>
        {booked} of {usage.bookingsIncluded} booked this month. {remaining} available.
      </div>
      {remainingAfterPurchase > 0 && (
        <div className={`${styles.usageLabel} ${styles.postPurchaseLabel}`}>
          {remainingAfterPurchase} slots available after this purchase.
        </div>
      )}
    </>
  );
});
