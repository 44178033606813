import { useEffect, useState } from 'react';
import { Api } from '../../api';
import { useFetch } from './useFetch';

export interface FetchPurchasesDownloadParams {
  userId: string | number;
  startDate?: string;
  endDate?: string;
}

const NULL_PURCHASES_DOWNLOAD: string = '';

export const useFetchPurchasesDownload = (params: FetchPurchasesDownloadParams) => {
  const [request, setRequest] = useState<Promise<string>>();
  useEffect(() => {
    const { userId, startDate, endDate } = params;
    if (!userId || !startDate || !endDate) {
      return;
    }
    setRequest(Api.users.purchasesDownload(userId, { startDate, endDate }));
  }, [params]);
  return useFetch<string>(request, NULL_PURCHASES_DOWNLOAD);
};
