import React from 'react';
import format from 'date-fns/format';

import styles from './CalendarIcon.module.scss';

export const CalendarIcon = React.memo<{ date: Date }>(({ date }) => {
  const monthAbbreviation = format(date, 'MMM').toUpperCase();
  const dayOfMonth = format(date, 'd');

  return (
    <div title={String(date)} className={`${styles.calendarIcon} text-center border border-light rounded`}>
      <div className={`${styles.calendarIconTop} border-bottom border-dark`}>{monthAbbreviation}</div>
      <div className={`${styles.calendarIconBottom} font-weight-bold pt-1 pb-2`}>{dayOfMonth}</div>
    </div>
  );
});