import React from 'react';
import { Link } from 'react-router-dom';

import { Card, CardBody, CardTitle } from '../../layout';
import styles from './MemberList.module.scss';

interface MemberListItem {
  name: string;
  image_url: string;
  text: string;
  link: MemberListItemLink;
} 

interface MemberListItemLink {
  label: string;
  url: string;
}

export const MemberList = React.memo<{ members: MemberListItem[], title: string }>(({ title, members }) => {
  return (
    <Card>
      <CardBody thin>
        <CardTitle><h2>{title}</h2></CardTitle>
        <div className="py-2">
          <ul className="list-unstyled mb-0">
            { members.map((member, idx) => {
              const { name, image_url, text, link } = member;

              return (
                <li key={idx} className="d-flex align-items-center w-100 py-2 border-top border-light">
                  <div className={styles.imageCol}>
                    {/* Hardcoding an image in assets for testing */}
                    {/* TODO: replace this with the actual image_url in api response */}
                    <img className={`${styles.memberImage} rounded-circle border border-light`}
                      src={require('../../assets/icons/evening.svg')} 
                      alt={name} />
                  </div>

                  <div className={`${styles.textCol} mx-3`}>
                    <div className={styles.name}>{name}</div>
                    <div className={styles.label}>{text}</div>
                  </div>

                  <div className={styles.linkCol}>
                    <Link to={link.url} className={`${styles.memberLink} btn btn-primary py-2 px-1 w-100`}>
                      {link.label}
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </CardBody>
    </Card>
  )
});