import React from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

import { Event } from './EventListContainer';
import { CalendarIcon } from './CalendarIcon';
import styles from './EventList.module.scss';

export const EventList = React.memo<{ events: Event[], className: string }>(({ events, className }) => {
  return (
    <ul className={`${className} mb-0`}>
      {events.map(event => {
        const { id, type, location, datetime, link } = event;

        // convert string like "2021-01-01 21:40:29 -0600" to proper ISO 8601 "2021-01-01T21:40:29-0600"
        const isoDate = datetime.replace(' ', 'T').replace(' ', '');
        const dateObj = new Date(isoDate);

        return (
          <li key={id} className="row align-items-md-center py-2 border-top border-light">
            <div className="col-3 col-md-1 align-self-center">
              <CalendarIcon date={dateObj} />
            </div>

            <div className="col row align-items-center flex-md-row flex-column">
              <div className="col col-md-5 my-2">
                <div className={styles.label}>Type</div>
                <div>{type}</div>
              </div>

              <div className="col my-2">
                <div className={styles.label}>Location</div>
                <div>{location}</div>
              </div>
            </div>

            <div className="col-5 row align-items-center flex-md-row flex-column">
              <div className="col my-2">
                <div className={styles.label}>Time</div>
                <div>{format(dateObj, 'h:mm a')}</div>
              </div>

              <div className="col text-md-right my-2">
                <Link to={link.url}>{link.label}</Link>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
});