import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { Location } from '../models';
import { useFetch } from './useFetch';

const NULL_LOCATIONS: Location[] = [];

export const useFetchLocations = () => {
  const [request, setRequest] = useState<Promise<Location[]>>();
  useEffect(() => setRequest(Api.locations.list()), []);
  return useFetch<Location[]>(request, NULL_LOCATIONS);
};
