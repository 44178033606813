import React, { useEffect, useState } from 'react';

import { User, UserStatus } from '../../api/models';
import { BasicSelect } from '../../common';

interface MemberStatusBlockProps {
  user: User;
  loading: boolean;
  onChangeStatus: (status: UserStatus) => void;
  onResendInvite: () => void;
}

export const MemberStatusBlock = React.memo((props: MemberStatusBlockProps) => {
  const { user, loading, onChangeStatus, onResendInvite } = props;

  const [status, setStatus] = useState(user.status);
  useEffect(() => setStatus(user.status), [user.status]);

  const pending = status === 'pending';

  return (
    <div>
      <BasicSelect
        id="user-status"
        label="Status"
        value={status}
        disabled={pending || loading}
        onChange={(evt) => setStatus(evt.currentTarget.value as UserStatus)}
      >
        {pending && <option value="pending">Pending</option>}
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </BasicSelect>

      {pending && (
        <div className="mt-3">
          <button type="button" className="btn btn-primary" disabled={loading} onClick={() => onChangeStatus('active')}>
            Approve User
          </button>
        </div>
      )}

      {!pending && status !== user.status && (
        <div className="mt-3">
          <button type="button" className="btn btn-primary" disabled={loading} onClick={() => onChangeStatus(status)}>
            Change Status
          </button>
        </div>
      )}

      {pending && (
        <div className="mt-3">
          <button type="button" className="btn btn-sm p-0 btn-link" disabled={loading} onClick={onResendInvite}>
            Resend Invite
          </button>
        </div>
      )}
    </div>
  );
});
