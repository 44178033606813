import addDays from 'date-fns/addDays';
import format from 'date-fns/format';

import { FetchHookResponse } from './useFetch';

/**
 * Returns an object with a start date and end date in the format that the API requires for filtering.
 * Derived from an array of dates, assuming the start date is at index 0 and the end date is at index n - 1
 *
 * @param dates An array of date objects that the current calendar represents
 */
export function parseStartEndDateFromArray(dates: Date[]): { startDate: string; endDate: string } {
  const startDate = dates[0];
  const endDate = dates[dates.length - 1];
  return {
    startDate: startDate ? format(startDate, 'yyyy-MM-dd') : '',
    endDate: endDate ? format(addDays(endDate, 1), 'yyyy-MM-dd') : '',
  };
}

export function isLoading(response: FetchHookResponse<any>) {
  return response.status === 'loading';
}

export function isError(response: FetchHookResponse<any>) {
  return response.status === 'error';
}

export function isResolved(response: FetchHookResponse<any>) {
  return response.status === 'resolved';
}
