import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { MembershipAgreementTemplate } from '../models';
import { useFetch } from './useFetch';

type FetchMembershipAgreementParams = {};

const NULL_BOOKING_AGREEMENT: MembershipAgreementTemplate = {
  html: '',
};

export const useFetchMembershipAgreement = (params: FetchMembershipAgreementParams) => {
  const [request, setRequest] = useState<Promise<MembershipAgreementTemplate>>();
  useEffect(() => {
    if (params === undefined) {
      return;
    }
    setRequest(Api.membershipAgreement.fetch());
  }, [params]);
  return useFetch<MembershipAgreementTemplate>(request, NULL_BOOKING_AGREEMENT);
};
