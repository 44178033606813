import React from 'react';

const locale = 'en-US';

interface CurrencyFormatProps {
  amount: number; // Amount in cents
  currency?: string;
}
export const CurrencyFormat = React.memo(({ amount, currency }: CurrencyFormatProps) => {
  const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: currency });
  return <>{formatter.format(parseFloat((amount / 100).toFixed(2)))}</>;
}) as any;
CurrencyFormat.defaultProps = {
  currency: "USD"
};
