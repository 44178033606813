import React, { useEffect, useMemo, useRef } from 'react';
import { useForm, UseFormOptions } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import { GeneralInfoSchema } from './formSchema';
import { BasicInput, Card } from '../../common';
import { ProgressSteps } from './ProgressSteps';
import { OnboardingSteps } from './OnboardingContainer';

export interface GeneralInfoFormSchema {
  firstName: string;
  lastName: string;
  practiceName: string;
  specialty: string;
  email: string;
  phoneNumber: string;
}

export const GENERAL_INFO_DEFAULTS: GeneralInfoFormSchema = {
  firstName: '',
  lastName: '',
  practiceName: '',
  specialty: '',
  email: '',
  phoneNumber: '',
};

interface FormProps {
  disabled?: boolean;
  onFinish: (formValues: GeneralInfoFormSchema) => void;
  defaultValues: GeneralInfoFormSchema;
}

export const GeneralInfo = React.memo((props: FormProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { disabled, defaultValues, onFinish } = props;

  const formOptions = useMemo<UseFormOptions<GeneralInfoFormSchema>>(() => {
    return {
      resolver: yupResolver(GeneralInfoSchema, {
        abortEarly: false,
        recursive: true,
        strict: false,
      }),
    };
  }, []);

  const { register, errors, handleSubmit, reset } = useForm<GeneralInfoFormSchema>(formOptions);
  useEffect(() => reset(defaultValues), [reset, defaultValues]);

  return (
    <div className="col-12 col-lg-8">
      <div className="d-flex flex-column align-items-center text-center">
        <h1>Let's get you all set up!</h1>
        <p>First, we need some basic info.</p>
        <ProgressSteps currentStep={OnboardingSteps.GENERAL_INFO} />
      </div>
      <Card>
        <form onSubmit={handleSubmit(onFinish)}>
          <div className="row">
            <div className="col-12 col-sm-4 font-weight-bold mb-3">Personal</div>
            <div className="col">
              <BasicInput
                id="firstName"
                label="First Name"
                errorMessage={errors.firstName?.message}
                name="firstName"
                disabled={disabled}
                ref={register}
              />
              <BasicInput
                id="lastName"
                label="Last Name"
                errorMessage={errors.lastName?.message}
                name="lastName"
                disabled={disabled}
                ref={register}
              />
              <BasicInput
                id="practiceName"
                label="Practice Name"
                errorMessage={errors.practiceName?.message}
                name="practiceName"
                disabled={disabled}
                ref={register}
              />
              <BasicInput
                id="specialty"
                label="Specialty"
                errorMessage={errors.specialty?.message}
                name="specialty"
                disabled={disabled}
                ref={register}
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12 col-sm-4 font-weight-bold mb-3">Contact</div>
            <div className="col">
              <BasicInput
                id="email"
                label="Email Address"
                errorMessage={errors.email?.message}
                name="email"
                disabled={disabled}
                ref={register}
              />
              <BasicInput
                id="phoneNumber"
                label="Phone Number"
                errorMessage={errors.phoneNumber?.message}
                name="phoneNumber"
                disabled={disabled}
                ref={register}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-secondary" type="submit" disabled={disabled}>
              Next
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
});
