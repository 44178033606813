import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { KPI } from '../models';
import { useFetch } from './useFetch';

const NULL_KPI: KPI = {
  activeMembers: 0,
  pendingMembers: 0,
  inactiveMembers: 0,
  membersCurrentlyBooked: 0,
  totalLocations: 0,
  totalRooms: 0,
  timeSlotsAvailable: 0,
  timeSlotsBooked: 0,
  totalMembers: 0,
};

export const useFetchKPI = () => {
  const [request, setRequest] = useState<Promise<KPI>>();
  useEffect(() => setRequest(Api.kpi.get()), []);
  return useFetch<KPI>(request, NULL_KPI);
};
