import { useDebounce } from '@react-hook/debounce';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import startOfWeek from 'date-fns/startOfWeek';

import { TimeSlot } from '../../api/models';
import { MemberRoutes } from '../Routes';
import { LocationSummaryBlock } from '../../common';
import {
  useTimeSlots,
  useFetchLocation,
  useFetchLocationAvailability,
  useFetchLocationAvailabilityParams,
  isResolved,
  isLoading,
} from '../../api/hooks';
import { Card, CardBackButton, CardBody } from '../../layout';
import {
  Calendar,
  CalendarMobile,
  DatePaginator,
  useDatePagination,
  useDateRangeFromPagination,
  useMobileWidth,
} from '../../calendar';
import { CalendarBlock, CalendarBlockData, CustomCalendarBlockProps } from './CalendarBlock';
import { BookingModal } from './BookingModal';
import { BookingModalProps } from './BookingModal/BookingModal';

const NULL_BOOKING_MODAL_DATA = {
  date: '',
  timeSlot: undefined,
  location: undefined,
  rooms: [],
  availability: [],
};

export const ScheduleContainer = React.memo(() => {
  const { locationId } = useParams();
  const [bookingModalData, setBookingModalData] = useState<BookingModalProps>(NULL_BOOKING_MODAL_DATA);

  const startDate = useMemo(() => startOfWeek(new Date()), []);
  const datePagination = useDatePagination(startDate, 7);
  const dateRangeForCalendar = useDateRangeFromPagination(datePagination.dates);
  const datePaginationPreviousDisabled = useMemo(() => {
    if (datePagination.dates.length === 0) {
      return true;
    }
    return datePagination.dates[0].getTime() <= startDate.getTime();
  }, [datePagination.dates, startDate]);

  // Fetch time slots
  const timeSlots = useTimeSlots();

  // Fetch location
  const locationFetchParams = useMemo(() => ({ locationId }), [locationId]);
  const locationResponse = useFetchLocation(locationFetchParams);

  // Fetch location calendar availability
  const [debouncedDates, setDebouncedDates] = useDebounce(datePagination.dates, 250);
  useEffect(() => setDebouncedDates(datePagination.dates), [datePagination.dates]);
  const availabilityFetchParams = useFetchLocationAvailabilityParams(locationId, debouncedDates);
  const locationAvailabilityResponse = useFetchLocationAvailability(availabilityFetchParams);

  // Merge data to render calendar cells
  const calendarData: CalendarBlockData = useMemo(() => {
    const availability = {};
    const map: CalendarBlockData = {
      locationId: locationId,
      availability: availability,
    };

    locationAvailabilityResponse.data.forEach((block) => {
      const key = `${block.date}-${block.timeSlotId}`;
      if (!map.availability[key]) {
        map.availability[key] = {
          available: 0,
          unavailable: 0,
          booked: 0,
        };
      }

      map.availability[key][block.status] = map.availability[key][block.status] + 1;
    });
    return map;
  }, [locationAvailabilityResponse.data]);

  const calendarBlockProps: CustomCalendarBlockProps = useMemo(() => {
    return {
      onSelect: (date: string, timeSlot: TimeSlot) => {
        const availability = locationAvailabilityResponse.data.filter(
          (x) => x.timeSlotId === timeSlot.id && x.date === date,
        );
        setBookingModalData({
          location: locationResponse.data,
          rooms: locationResponse.data.rooms,
          availability,
          date,
          timeSlot,
        });
      },
    };
  }, [locationAvailabilityResponse.data, locationResponse.data]);

  // Booking modal
  const readyToShowModal = useMemo(() => {
    const { location, timeSlot, date } = bookingModalData;
    return (
      location &&
      timeSlot &&
      date.length > 0 &&
      isResolved(locationResponse) &&
      isResolved(locationAvailabilityResponse)
    );
  }, [bookingModalData, locationResponse, locationAvailabilityResponse]);

  const hideModal = useCallback(() => setBookingModalData(NULL_BOOKING_MODAL_DATA), []);

  // Determine which calendar to render based on browser width
  const CalendarComponent = useMobileWidth() ? CalendarMobile : Calendar;

  return (
    <Card>
      <CardBody thin>
        <CardBackButton to={MemberRoutes.Locations}>Back to Locations</CardBackButton>
      </CardBody>
      <CardBody>
        <LocationSummaryBlock location={locationResponse.data} />
      </CardBody>
      <CardBody borderTop borderBottom thin>
        <DatePaginator {...datePagination} prevDisabled={datePaginationPreviousDisabled} />
      </CardBody>
      <CardBody>
        <CalendarComponent<CalendarBlockData, CustomCalendarBlockProps>
          data={calendarData}
          dates={dateRangeForCalendar}
          timeSlots={timeSlots}
          loading={isLoading(locationAvailabilityResponse)}
          CellComponent={CalendarBlock}
          cellProps={calendarBlockProps}
        />
      </CardBody>
      <BookingModal show={readyToShowModal} onHide={hideModal} {...bookingModalData} />
    </Card>
  );
});
