import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { useForm, UseFormOptions } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { Api } from '../../api';
import { LocationRoom } from '../../api/models';
import { BasicInput, BasicSelect } from '../../common';
import { isResolved, useFetchLocations, useFetchSubscriptions } from '../../api/hooks';

interface InviteFormValues {
  firstName: string;
  lastName: string;
  email: string;
  subscriptionId: string;
  locationId: string;
  roomId: string;
}

export const InviteModal = React.memo((props: ModalProps) => {
  const { onHide, ...modalProps } = props;

  const subscriptionsResponse = useFetchSubscriptions();
  const locationsResponse = useFetchLocations();

  const formOptions = useMemo<UseFormOptions<InviteFormValues>>(() => {
    return {
      defaultValues: {
        firstName: '',
        lastName: '',
        email: '',
      },
      resolver: yupResolver<InviteFormValues>(
        yup.object().shape({
          firstName: yup.string().required().label('First Name'),
          lastName: yup.string().required().label('Last Name'),
          email: yup.string().email().required().label('Email Address'),
          locationId: yup.string(),
          roomId: yup.string().when('locationId', {
            is: (val) => val !== '',
            then: yup.string().required('You must select a room if you select a location.'),
          }),
        }),
        {
          abortEarly: false,
        },
      ),
      reValidateMode: 'onBlur',
    };
  }, []);
  const { register, errors, handleSubmit, setValue, watch } = useForm<InviteFormValues>(formOptions);

  const locationId = watch('locationId');
  const [rooms, setRooms] = useState<LocationRoom[]>([]);
  useEffect(() => {
    const locationIdParsed = parseInt(locationId, 10);
    const location = locationsResponse.data.find((location) => location.id === locationIdParsed);
    setValue('roomId', '');
    if (location) {
      setRooms(location.rooms);
    } else {
      setRooms([]);
    }
  }, [locationId, setValue]);

  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async (values: InviteFormValues) => {
      setLoading(true);
      try {
        const payload = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          subscriptionId: parseInt(values.subscriptionId, 10),
          locationId: values.locationId ? parseInt(values.locationId, 10) : undefined,
          roomId: values.roomId ? parseInt(values.roomId, 10) : undefined,
        };
        const response = await Api.auth.signUp(payload);
        onHide(response);
      } catch (e) {
        alert('TODO: Could not add user, view error in console');
        console.error(e);
      }
      setLoading(false);
    },
    [onHide],
  );

  const disabled = loading || !isResolved(subscriptionsResponse);

  const wrappedOnHide = useCallback(() => {
    if (disabled) {
      return;
    }
    onHide();
  }, [disabled, onHide]);

  return (
    <Modal {...modalProps} onHide={wrappedOnHide} centered aria-labelledby="user-invite-modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title id="user-invite-modal">Add New Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <BasicInput
              id="invite-first-name"
              label="First Name"
              errorMessage={errors.firstName?.message}
              name="firstName"
              disabled={disabled}
              ref={register}
            />
            <BasicInput
              id="invite-last-name"
              label="Last Name"
              errorMessage={errors.lastName?.message}
              name="lastName"
              disabled={disabled}
              ref={register}
            />
            <BasicInput
              id="invite-email"
              label="Email"
              errorMessage={errors.email?.message}
              name="email"
              disabled={disabled}
              ref={register}
            />
            <BasicSelect
              id="invite-subscription"
              label="Subscription"
              errorMessage={errors.subscriptionId?.message}
              name="subscriptionId"
              disabled={disabled}
              ref={register}
            >
              {subscriptionsResponse.data.map((sub) => (
                <option key={sub.id} value={sub.id}>
                  {sub.name}
                </option>
              ))}
            </BasicSelect>
            <BasicSelect id="invite-location" label="Location" name="locationId" ref={register}>
              <option value="">Select a location</option>
              {locationsResponse.data.map((location) => {
                return (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                );
              })}
            </BasicSelect>
            <BasicSelect
              id="invite-room"
              label="Room"
              errorMessage={errors.roomId?.message}
              name="roomId"
              ref={register}
              disabled={!locationId || rooms.length === 0}
            >
              <option value="">Select a room</option>
              {rooms.map((room) => {
                return (
                  <option key={room.id} value={room.id}>
                    {room.name}
                  </option>
                );
              })}
            </BasicSelect>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary" disabled={disabled}>
            Create Member
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
});
