import React from 'react';

import { TimeSlot } from '../api/models';

interface TimeSlotIconProps {
  timeSlot: TimeSlot;
  size?: 'sm' | 'md';
}

export const TimeSlotIcon = React.memo(({ timeSlot, size = 'md' }: TimeSlotIconProps) => {
  // TODO: Determine how to get the icon for a time slot
  let icon;
  switch (timeSlot.name) {
    case 'Morning': {
      icon = require('../assets/icons/morning.svg');
      break;
    }
    case 'Afternoon': {
      icon = require('../assets/icons/afternoon.svg');
      break;
    }
    case 'Evening': {
      icon = require('../assets/icons/evening.svg');
      break;
    }
    default: {
      icon = require('../assets/icons/afternoon.svg');
    }
  }

  let width;
  switch (size) {
    case 'sm':
      width = 30;
      break;
    case 'md':
    default:
      width = 50;
  }

  return <img src={icon} width={width} alt={timeSlot.name} />;
});
