import React, { useMemo } from 'react';

import { LocationRoom, Location, TimeSlot } from '../../api/models';
import { CalendarBlockProps } from '../../calendar';

import styles from './CalendarBlock.module.scss';

export interface CalendarBlockData {
  [key: string]: {
    location: Location;
    rooms: LocationRoom[];
  }[];
}

export interface CustomCalendarBlockProps {
  onCancel: (date: string, timeSlot: TimeSlot) => void;
}

export const CalendarBlock = React.memo((props: CalendarBlockProps<CalendarBlockData, CustomCalendarBlockProps>) => {
  const {
    date,
    timeSlot,
    data,
    cellProps: { onCancel },
  } = props;

  const key = useMemo(() => `${date}-${timeSlot.id}`, [date, timeSlot]);

  let bookings = data[key];
  if (!bookings) {
    bookings = [];
  }

  return (
    <div className="flex-fill d-flex flex-column position-relative">
      <div className={styles.dashes}>
        <div className={styles.dash} />
        <div className={styles.dash} />
        <div className={styles.dash} />
        <div className={styles.dash} />
      </div>
      {bookings.length > 0 && (
        <div className={`${styles.bookedSlot} d-flex flex-column flex-fill py-2 px-3`}>
          {bookings.map((booking, index) => {
            return (
              <div className="flex-fill" key={booking.location.id}>
                <div className={styles.location}>{booking.location.name}</div>
                {booking.rooms.map((room) => {
                  return (
                    <div key={room.id} className={styles.room}>
                      {room.name}
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div>
            <button type="button" className="btn btn-link p-0" onClick={() => onCancel(date, timeSlot)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
});
