import { useEffect, useState } from 'react';

import { Api } from '../../api';
import { Purchase, BookingPreviewRequest } from '../models';
import { useFetch } from './useFetch';

const NULL_PURCHASE: any = {};

interface RequestBookingParams {
  checksum: string;
  bookings: BookingPreviewRequest[];
}

export const useRequestBooking = (params: RequestBookingParams) => {
  const [request, setRequest] = useState<Promise<Purchase>>();
  useEffect(() => {
    const { bookings, checksum } = params;
    if (bookings.length === 0 || checksum.length === 0) {
      return;
    }
    setRequest(Api.bookings.create(bookings, checksum));
  }, [params]);
  return useFetch<Purchase>(request, NULL_PURCHASE);
};
