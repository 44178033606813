import React from 'react';

import { User } from '../../api/models';
import styles from './MemberSummaryBlock.module.scss';

export const MemberSummaryBlock = React.memo(({ user }: { user: User }) => {
  let name = `${user.firstName} ${user.lastName}`.trim();
  if (name.length > 0) {
    name = `Dr. ${name}`;
  }
  const npi = (user.member && user.member.npi) || 'Unavailable';
  const practiceName = user.member && user.member.practiceName;
  const phone = user.member && user.member.phone;

  return (
    <div>
      <h3>{name}</h3>
      <div className={styles.npi}>NPI# {npi}</div>
      {practiceName && <div className={styles.practiceName}>{practiceName}</div>}
      <a className={styles.email} href={`mailto:${user.email}`}>
        {user.email}
      </a>
      {phone && <div className={styles.phone}>{phone}</div>}
    </div>
  );
});
