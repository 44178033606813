import React from 'react';

import styles from './BookedProgressBar.module.scss';

interface BookedProgressBarProps {
  value: number; // For 5%, 0.05, not 5
}

export const BookedProgressBar = React.memo(({ value }: BookedProgressBarProps) => {
  const percentage = Math.ceil(value * 100) + '%';
  return (
    <div className={styles.container}>
      <div className={styles.progressContainer}>
        <div className={styles.progressBackground} />
        <div className={styles.progressBar} style={{ width: percentage }} />
      </div>
      <div className={styles.label}>{percentage} booked this week</div>
    </div>
  );
});
