import React from 'react';
import { Link } from 'react-router-dom';

import { Card, CardBody } from '../../layout';
import { MemberRoutes } from '../Routes';

export const ShoppingCartEmpty = React.memo(() => {
  return (
    <Card>
      <CardBody>
        <div className="text-center py-5">
          <div className="mb-3">There are no items in your cart</div>
          <Link className="btn btn-primary" to={MemberRoutes.Locations}>
            Book Space
          </Link>
        </div>
      </CardBody>
    </Card>
  );
});
