import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useTimeSlots } from '../api/hooks';
import { LocationsContainer } from './locations';
import { LocationContainer } from './location';
import { UsersContainer } from './users';
import { UserContainer } from './user';
import { AdminNav } from './nav/AdminNav';
import { DashboardContainer } from './dashboard'

export const AdminRoutePrefix = '/admin';
export enum AdminRoutes {
  Root = '/admin/dashboard',
  Dashboard = '/admin/dashboard',
  Users = '/admin/users',
  User = '/admin/users/:userId',
  Locations = '/admin/locations',
  Location = '/admin/locations/:locationId',
}

export default React.memo(() => {
  useTimeSlots();
  return (
    <>
      <AdminNav />
      <div className="container-fluid py-4 smd-routes-top-margin">
        <Switch>
          <Route exact path={AdminRoutes.Users}>
            <UsersContainer />
          </Route>
          <Route exact path={AdminRoutes.User}>
            <UserContainer />
          </Route>
          <Route exact path={AdminRoutes.Locations}>
            <LocationsContainer />
          </Route>
          <Route exact path={AdminRoutes.Location}>
            <LocationContainer />
          </Route>
          <Route exact path={AdminRoutes.Dashboard}>
            <DashboardContainer />
          </Route>
          <Route>
            <Redirect to={AdminRoutes.Root} />
          </Route>
        </Switch>
      </div>
    </>
  );
});
