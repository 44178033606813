import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';

export interface FetchHookResponse<T> {
  status: 'unresolved' | 'loading' | 'resolved' | 'error';
  data: T;
  error?: {
    code: number;
    message: string;
    data: any;
  };
  lastUpdate: Date;
}

export function useFetch<T>(promise: Promise<T> | undefined, defaultValue: T) {
  const initialPayload = useMemo<FetchHookResponse<T>>(
    () => ({
      status: 'unresolved',
      data: defaultValue,
      error: undefined,
      lastUpdate: new Date(),
    }),
    [defaultValue],
  );

  const [payload, setPayload] = useState<FetchHookResponse<T>>(initialPayload);

  useEffect(() => {
    if (!promise) {
      setPayload({
        ...initialPayload,
        lastUpdate: new Date(),
      });
      return;
    }

    fetch();

    async function fetch() {
      if (!promise) {
        return;
      }

      setPayload({
        ...initialPayload,
        status: 'loading',
        lastUpdate: new Date(),
      });

      try {
        setPayload({
          ...initialPayload,
          status: 'resolved',
          data: await promise,
          lastUpdate: new Date(),
        });
      } catch (e) {
        const axiosError = e as AxiosError;
        let code = 400;
        let message = 'Unknown error has occurred';
        let data = {};
        if (axiosError.isAxiosError && axiosError.response) {
          const response = axiosError.response;
          code = axiosError.response.status;
          if (response.data && response.data.data) {
            if (response.data.data.message) {
              message = response.data.data.message;
            }
            if (response.data.data.error) {
              message = response.data.data.error;
            }
          }
          if (axiosError.response.data && axiosError.response.data.data) {
            data = axiosError.response.data.data;
          }
        }
        setPayload({
          ...initialPayload,
          status: 'error',
          error: {
            code,
            message,
            data,
          },
          lastUpdate: new Date(),
        });
      }
    }
  }, [promise, initialPayload]);

  return payload;
}
