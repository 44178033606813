import * as rax from 'retry-axios';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { AuthRoutePrefix, AuthRoutes } from '../auth';
import { Api } from './actions';
import { JWT } from './models';
import { convertCamelToSnake, convertSnakeToCamel, convertCamelToHyphen, transformKeys } from './caseConversion';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
rax.attach();

const DEFAULT_JWT = { token: '', refreshToken: '', expiration: 0, userId: -1 };

const JWT_LOCAL_STORAGE_KEY = 'smdAuth';
let jwt: JWT = DEFAULT_JWT;

export function setupAuthHeader(_jwt: JWT) {
  jwt = _jwt;
}

export function storeJwt(_jwt: JWT) {
  localStorage.setItem(JWT_LOCAL_STORAGE_KEY, JSON.stringify(_jwt));
}

export function logOut() {
  localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
  localStorage.removeItem('smdCart');
}

export function retrieveJwt(): JWT {
  try {
    return JSON.parse(localStorage.getItem(JWT_LOCAL_STORAGE_KEY) || '');
  } catch (e) {
    localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
    return DEFAULT_JWT;
  }
}

axios.interceptors.response.use(function (response) {
  response.data = transformKeys(response.data, convertSnakeToCamel);
  return response;
});

axios.interceptors.request.use(function (request) {
  request.data = transformKeys(request.data, convertCamelToSnake);
  request.params = transformKeys(request.params, convertCamelToHyphen);
  if (jwt.token) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${jwt.token}`,
    };
  }

  return request;
});

interface ApiResponse<T> {
  data: T;
}

export function get<T>(url: string, config?: AxiosRequestConfig) {
  return axios.get<ApiResponse<T>>(url, attachRaxConfig(config)).then((response) => response.data.data);
}

export function put<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return axios.put<ApiResponse<T>>(url, data, attachRaxConfig(config)).then((response) => response.data.data);
}

export function post<T>(url: string, data: any, config?: AxiosRequestConfig) {
  return axios.post<ApiResponse<T>>(url, data, attachRaxConfig(config)).then((response) => response.data.data);
}

export function destroy<T>(url: string, config?: AxiosRequestConfig) {
  return axios.delete<ApiResponse<T>>(url, attachRaxConfig(config)).then((response) => response.data.data);
}

const MAX_RETRY_ATTEMPTS = 1;
function attachRaxConfig(config: AxiosRequestConfig = {}) {
  return {
    ...config,
    raxConfig: {
      statusCodesToRetry: [[401, 401]],
      onRetryAttempt: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Api.auth.refresh(jwt.refreshToken);
            storeJwt(response);
            setupAuthHeader(response);
            resolve();
          } catch (e) {
            logOut();
            alert('Your session has expired, you will be redirected to the login screen');
            window.location.assign(AuthRoutes.SignIn);
            reject();
          }
        });
      },
      shouldRetry: (err: AxiosError) => {
        if (!err.config.raxConfig) {
          return false;
        }
        if (
          err.config.raxConfig.currentRetryAttempt === undefined ||
          err.config.raxConfig.currentRetryAttempt > MAX_RETRY_ATTEMPTS - 1
        ) {
          return false;
        }

        if (!err.config.url || err.config.url.indexOf(AuthRoutePrefix) === 0) {
          return false;
        }
        return true;
      },
    },
  };
}
