import { useEffect, useMemo, useState } from 'react';

import { Api } from '../../api';
import { GenericId, UserBooking } from '../models';
import { useFetch } from './useFetch';
import { parseStartEndDateFromArray } from './fetchUtils';

interface FetchUserBookingsParams {
  userId: GenericId;
  startDate: string;
  endDate: string;
}

const NULL_BOOKINGS: UserBooking[] = [];

export const useFetchUserBookings = (params: FetchUserBookingsParams) => {
  const [request, setRequest] = useState<Promise<UserBooking[]>>();

  useEffect(() => {
    const { userId, startDate, endDate } = params;
    if (!userId || !startDate || !endDate) {
      return;
    }
    setRequest(Api.userBookings.list(userId, { startDate, endDate }));
  }, [params]);
  return useFetch<UserBooking[]>(request, NULL_BOOKINGS);
};

export const useFetchUserBookingsParams = (userId: GenericId, dates: Date[]): [FetchUserBookingsParams, () => void] => {
  const [refreshToken, setRefreshToken] = useState(new Date());
  const params = useMemo(() => {
    return {
      ...parseStartEndDateFromArray(dates),
      userId,
    };
  }, [userId, dates, refreshToken]);
  return useMemo(() => {
    return [params, () => setRefreshToken(new Date())];
  }, [params]);
};
