import { useEffect, useMemo, useState } from 'react';

import { Api } from '../../api';
import { GenericId, LocationBooking } from '../models';
import { useFetch } from './useFetch';
import { parseStartEndDateFromArray } from './fetchUtils';

interface FetchLocationBookingsParams {
  locationId: GenericId;
  startDate: string;
  endDate: string;
}

const NULL_BOOKINGS: any[] = [];

export const useFetchLocationBookings = (params: FetchLocationBookingsParams) => {
  const [request, setRequest] = useState<Promise<LocationBooking[]>>();
  useEffect(() => {
    const { locationId, startDate, endDate } = params;
    if (!locationId || !startDate || !endDate) {
      return;
    }
    setRequest(Api.locationBookings.list(locationId, { startDate, endDate }));
  }, [params]);
  return useFetch<LocationBooking[]>(request, NULL_BOOKINGS);
};

export const useFetchLocationBookingsParams = (locationId: GenericId, dates: Date[]): FetchLocationBookingsParams => {
  return useMemo(
    () => ({
      ...parseStartEndDateFromArray(dates),
      locationId,
    }),
    [locationId, dates],
  );
};
