import React, { useEffect, useMemo } from 'react';
import { Controller, useForm, UseFormOptions } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import Flatpickr from 'react-flatpickr';

import { DocumentsSchema } from './formSchema';
import { BasicInlineCheckbox, BasicInput, Card } from '../../common';
import { GeneralInfoFormSchema } from './GeneralInfo';
import { MembershipAgreement } from './MembershipAgreement';
import { OnboardingSteps } from './OnboardingContainer';
import { ProgressSteps } from './ProgressSteps';
import { MediaCategory, MediaResource, User } from '../../api/models';

import photoIdIcon from '../../assets/icons/photo-id.svg';
import insuranceIcon from '../../assets/icons/insurance.svg';
import npiIcon from '../../assets/icons/npi-icon.svg';
import 'flatpickr/dist/themes/airbnb.css';
import styles from '../../common/BasicInput.module.scss';

export interface DocumentsFormSchema {
  idNumber: string;
  idStateIssued: string;
  idExpirationDate: string;
  insuranceProvider: string;
  insurancePolicyNumber: string;
  insurancePolicyExpirationDate: string;
  npi: string;
  membershipAgreementAccepted: boolean;
}

export const DOCUMENTS_DEFAULTS: DocumentsFormSchema = {
  idNumber: '',
  idStateIssued: '',
  idExpirationDate: '',
  insuranceProvider: '',
  insurancePolicyNumber: '',
  insurancePolicyExpirationDate: '',
  npi: '',
  membershipAgreementAccepted: false,
};

interface FormProps {
  disabled?: boolean;
  defaultValues: DocumentsFormSchema;
  identificationMedia?: MediaResource;
  insuranceMedia?: MediaResource;
  membershipAgreementTemplate: string;
  user: User;
  generalInfo: GeneralInfoFormSchema;
  onUploadMedia: (category: MediaCategory, file?: File | null) => void;
  onFinish: (formValues: DocumentsFormSchema) => void;
  onBack: () => void;
}

export const Documents = React.memo((props: FormProps) => {
  const { defaultValues, identificationMedia, insuranceMedia } = props;
  const { onFinish, onBack, onUploadMedia } = props;

  const formOptions = useMemo<UseFormOptions<DocumentsFormSchema>>(() => {
    return {
      resolver: yupResolver(DocumentsSchema, {
        abortEarly: false,
        recursive: true,
        strict: false,
      }),
    };
  }, []);

  const { register, errors, handleSubmit, reset, control, watch } = useForm<DocumentsFormSchema>(formOptions);
  useEffect(() => reset(defaultValues), [reset, defaultValues]);
  const signed = watch('membershipAgreementAccepted');

  return (
    <div className="col-12 col-lg-8">
      <div className="d-flex flex-column align-items-center text-center">
        <h1>Let's get the paperwork out of the way.</h1>
        <p>You know, prove you're a real doctor and all…</p>
        <ProgressSteps currentStep={OnboardingSteps.DOCUMENTS} />
      </div>
      <Card>
        <form onSubmit={handleSubmit(onFinish)}>
          <div className="row">
            <div className="col-12 col-sm-4 d-flex flex-sm-column flex-row align-items-sm-start align-items-center mb-3">
              <div className="font-weight-bold">Photo Id</div>
              <img src={photoIdIcon} alt="Photo Id" className="mt-sm-3 ml-sm-0 ml-3" />
            </div>
            <div className="col">
              <BasicInput
                id="idNumber"
                label="Id Number"
                errorMessage={errors.idNumber?.message}
                name="idNumber"
                ref={register}
              />
              <div className="row">
                <div className="col-12 col-sm-6">
                  <BasicInput
                    id="idStateIssued"
                    label="State Issued"
                    errorMessage={errors.idStateIssued?.message}
                    name="idStateIssued"
                    ref={register}
                  />
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="idExpirationDate" className={styles.basicInputLabel}>
                    Expiration Date
                  </label>
                  <Controller
                    control={control}
                    name="idExpirationDate"
                    id="idExpirationDate"
                    register={register}
                    defaultValue={defaultValues.idExpirationDate}
                    render={({ onChange, value }) => (
                      <Flatpickr
                        value={value}
                        className={`form-control ${errors.idExpirationDate?.message ? 'is-invalid' : ''}`}
                        options={{ minDate: new Date() }}
                        onChange={(date) => {
                          onChange(date[0]);
                        }}
                      />
                    )}
                  />
                  {errors.idExpirationDate?.message && (
                    <div className="invalid-feedback d-block">{errors.idExpirationDate?.message}</div>
                  )}
                </div>
                <div className="col">
                  <BasicInput
                    id="idFile"
                    type="file"
                    label="Upload Photo Id"
                    name="idFile"
                    onChange={(evt) =>
                      onUploadMedia('identification', evt.currentTarget.files && evt.currentTarget.files[0])
                    }
                  />
                  {identificationMedia && <a href={identificationMedia.src}>View Photo Id</a>}
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-12 col-sm-4 d-flex flex-sm-column flex-row align-items-sm-start align-items-center mb-3">
              <div className="font-weight-bold">Insurance</div>
              <img src={insuranceIcon} alt="Insurance" className="mt-sm-3 ml-sm-0 ml-3" />
            </div>
            <div className="col">
              <BasicInput
                id="insuranceProvider"
                label="Insurance Provider"
                errorMessage={errors.insuranceProvider?.message}
                name="insuranceProvider"
                ref={register}
              />
              <div className="row">
                <div className="col-12 col-sm-6">
                  <BasicInput
                    id="insurancePolicyNumber"
                    label="Policy Number"
                    errorMessage={errors.insurancePolicyNumber?.message}
                    name="insurancePolicyNumber"
                    ref={register}
                  />
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="insurancePolicyExpirationDate" className={styles.basicInputLabel}>
                    Expiration Date
                  </label>
                  <Controller
                    control={control}
                    id="insurancePolicyExpirationDate"
                    name="insurancePolicyExpirationDate"
                    register={register}
                    defaultValue={defaultValues.insurancePolicyExpirationDate}
                    render={({ onChange, value }) => (
                      <Flatpickr
                        value={value}
                        className={`form-control ${errors.insurancePolicyExpirationDate?.message ? 'is-invalid' : ''}`}
                        options={{ minDate: new Date() }}
                        onChange={(date) => {
                          onChange(date[0]);
                        }}
                      />
                    )}
                  />
                  {errors.insurancePolicyExpirationDate?.message && (
                    <div className="invalid-feedback">{errors.insurancePolicyExpirationDate?.message}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <BasicInput
                    id="insurancePolicyFile"
                    type="file"
                    label="Upload Insurance Policy"
                    name="insurancePolicyFile"
                    onChange={(evt) =>
                      onUploadMedia('insurance', evt.currentTarget.files && evt.currentTarget.files[0])
                    }
                  />
                  {insuranceMedia && <a href={insuranceMedia.src}>View Insurance Policy</a>}
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-12 col-sm-4 d-flex flex-sm-column flex-row align-items-sm-start align-items-center mb-3">
              <div className="font-weight-bold">National Provider Identifier</div>
              <img src={npiIcon} alt="National Provider Identifier" className="mt-sm-3 ml-sm-0 ml-3" />
            </div>
            <div className="col">
              <BasicInput id="npi" label="NPI Number" errorMessage={errors.npi?.message} name="npi" ref={register} />
            </div>
          </div>

          <hr />

          <div>
            <div className="font-weight-bold">Membership Agreement</div>

            <div className="my-3">
              <MembershipAgreement
                template={props.membershipAgreementTemplate}
                generalInfo={props.generalInfo}
                user={props.user}
                signed={signed}
              />
            </div>

            <div>
              <BasicInlineCheckbox
                noGroupMargin
                name="membershipAgreementAccepted"
                id="membership-agreement-accepted"
                label="Agree and Sign"
                errorMessage={errors.membershipAgreementAccepted?.message}
                ref={register}
              />
            </div>
          </div>

          <hr />

          <div className="d-flex justify-content-between mt-3">
            <button type="button" className="btn btn-light" onClick={onBack}>
              Back
            </button>
            <button type="submit" className="btn btn-secondary">
              Next
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
});
