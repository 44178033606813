import React, { ReactChild } from 'react';

import { Card, CardBody, CardTitle } from '../layout';
import styles from './SimpleStatCard.module.scss';

interface SimpleStatCardProps {
  label: ReactChild;
  value: ReactChild;
  additional?: ReactChild;
}

export const SimpleStatCard = React.memo(({ label, value, additional }: SimpleStatCardProps) => {
  return (
    <Card className="flex-fill overflow-hidden">
      <CardBody thin>
        <CardTitle>{label}</CardTitle>
      </CardBody>
      <CardBody thin className="d-flex justify-content-between">
        <span className={styles.value}>{value}</span>
        <span className={'align-self-end ' + styles.additional}>{additional}</span>
      </CardBody>
    </Card>
  );
});
